// Home.js
import React from 'react';
import { FaUserGraduate, FaBook, FaUserPlus, FaDollarSign } from 'react-icons/fa';
import MetricsCard from './MetricsCard';
import RecentSignups from './RecentSignups';
import PopularCourses from './PopularCourses';
import UpcomingEvents from './UpcomingEvents';

const Home = () => {
  const signups = [
    { name: 'John Doe', email: 'johndoe@example.com', timeAgo: '2 days ago' },
    { name: 'Jane Doe', email: 'janedoe@example.com', timeAgo: '3 days ago' },
    { name: 'John Smith', email: 'johnsmith@example.com', timeAgo: '4 days ago' },
  ];

  const courses = [
    { title: 'Web Development', students: 120 },
    { title: 'Data Science', students: 80 },
    { title: 'Machine Learning', students: 90 },
  ];

  const events = [
    { title: 'Web Development Workshop', date: 'May 15, 2023' },
    { title: 'Data Science Meetup', date: 'June 5, 2023' },
    { title: 'Machine Learning Webinar', date: 'July 1, 2023' },
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6 p-4 md:p-6">
      {/* Metrics Section */}
      <MetricsCard icon={FaUserGraduate} color="blue" label="Active Students" value="1,234" />
      <MetricsCard icon={FaBook} color="green" label="Courses" value="78" />
      <MetricsCard icon={FaUserPlus} color="yellow" label="New Signups" value="42" />
      <MetricsCard icon={FaDollarSign} color="red" label="Revenue" value="$12,345" />

      {/* Recent Signups */}
      <div className="col-span-1 md:col-span-2">
        <RecentSignups signups={signups} />
      </div>

      {/* Popular Courses */}
      <div className="col-span-1 md:col-span-2 lg:col-span-1">
        <PopularCourses courses={courses} />
      </div>

      {/* Upcoming Events */}
      <div className="col-span-1 md:col-span-2 lg:col-span-1">
        <UpcomingEvents events={events} />
      </div>
    </div>
  );
};

export default Home;
