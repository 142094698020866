// components/PopularCourses.js
import React from 'react';

const PopularCourses = ({ courses }) => {
  return (
    <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300">
      <h2 className="text-lg md:text-xl font-bold mb-4 text-gray-800">Popular Courses</h2>
      <div className="space-y-2 md:space-y-4">
        {courses.map((course, index) => (
          <div key={index} className="flex justify-between items-center">
            <div>
              <div className="font-bold text-gray-900">{course.title}</div>
              <div className="text-gray-600">{course.students} students</div>
            </div>
            <button className="bg-blue-500 text-white px-2 md:px-3 py-1 rounded-md hover:bg-blue-600 transition">View</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PopularCourses;
