import React, { useState } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import PrivacyPolicyModal from './PrivacyPolicy';
import { urlPrefix } from "../Settings";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const [privacyCheckError, setPrivacyCheckError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // New state for loading
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    // Validate form fields
    let valid = true;

    if (!email) {
      setEmailError('Please enter a valid email address.');
      valid = false;
    } else if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
      valid = false;
    } else {
      setEmailError('');
    }

    if (!password || password.length < 8) {
      setPasswordError('Password requires a combination of uppercase and lowercase letters numbers and symbols');
      valid = false;
    } else {
      setPasswordError('');
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError('Password does not match the confirm password.');
      valid = false;
    } else {
      setConfirmPasswordError('');
    }

    if (!privacyCheck) {
      setPrivacyCheckError('Please accept our Privacy Policy.');
      valid = false;
    } else {
      setPrivacyCheckError('');
    }

    if (!valid) {
      return;
    }

    setIsLoading(true); // Set loading to true

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        Email: email,
        Password: password,
        ConfirmPassword: confirmPassword,
      })
    };

    fetch(`${urlPrefix}/User/registration`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (!result || !result.token) {
          setError("Email address already exists or other registration error.");
          return;
        }
        localStorage.setItem("id", result.id);
        localStorage.setItem("token", result.token);
        localStorage.setItem("statusId", result.statusId);
        setError("");
        navigate('/register-info'); // Navigate to register-info
      })
      .catch((error) => {
        console.error('Error:', error);
        setError('An error occurred during registration.');
      })
      .finally(() => {
        setIsLoading(false); // Reset loading state
      });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-gray-100 to-indigo-300 relative">
      {/* Back Button */}
      <div className="flex justify-start mb-7">
        <button 
          onClick={() => navigate('/')}  // Use navigate for back button
          className="absolute top-4 left-4 flex items-center text-blue-600 hover:text-blue-800 font-semibold text-lg">
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" /> Back to Home
        </button>
      </div>
      <div className="max-w-lg w-full bg-white p-8 rounded-lg shadow-md">
        <div className="text-center mb-6">
          <h1 className="text-2xl font-semibold mb-4 text-blue-600">Welcome to Learning Mission Armenia</h1>
          <p className="text-blue-600">Fill out the form to get started.</p>
        </div>

        {error && <div className="mb-4 text-red-600">{error}</div>}

        <form className="space-y-4" onSubmit={handleSubmit} noValidate>
          <div>
            <label className="block text-sm font-medium text-gray-700" htmlFor="signupSimpleSignupEmail">Email</label>
            <input
              type="email"
              className={`mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${emailError ? 'border-red-500' : ''}`}
              id="signupSimpleSignupEmail"
              placeholder="email@site.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {emailError && <div className="mt-1 text-red-500 text-sm">{emailError}</div>}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700" htmlFor="signupSimpleSignupPassword">Password</label>
            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                className={`mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${passwordError ? 'border-red-500' : ''}`}
                id="signupSimpleSignupPassword"
                placeholder="8+ characters required"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                minLength="8"
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500"
                onClick={() => setShowPassword(!showPassword)}
              >
                <i className={showPassword ? 'bi-eye' : 'bi-eye-slash'}></i>
              </button>
            </div>
            {passwordError && <div className="mt-1 text-red-500 text-sm">{passwordError}</div>}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700" htmlFor="signupSimpleSignupConfirmPassword">Confirm password</label>
            <div className="relative">
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                className={`mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${confirmPasswordError ? 'border-red-500' : ''}`}
                id="signupSimpleSignupConfirmPassword"
                placeholder="8+ characters required"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                <i className={showConfirmPassword ? 'bi-eye' : 'bi-eye-slash'}></i>
              </button>
            </div>
            {confirmPasswordError && <div className="mt-1 text-red-500 text-sm">{confirmPasswordError}</div>}
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              className={`form-check-input ${privacyCheckError ? 'border-red-500' : ''}`}
              id="signupHeroFormPrivacyCheck"
              name="signupFormPrivacyCheck"
              checked={privacyCheck}
              onChange={(e) => setPrivacyCheck(e.target.checked)}
              required
            />
            <label className="ml-2 text-sm text-gray-700" htmlFor="signupHeroFormPrivacyCheck">
              By submitting this form I have read and acknowledged the <span onClick={() => setShowModal(true)} className="text-blue-600 cursor-pointer hover:text-blue-500">Privacy Policy</span>
            </label>
          </div>
          {privacyCheckError &&  <span className="text-red-500 text-sm ml-2">{privacyCheckError}</span>}

          <div>
            <button 
              type="submit" 
              className={`w-full py-3 rounded-lg transition duration-200 flex justify-center items-center 
                ${isLoading ? 'bg-blue-300 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700 text-white'}`}
              disabled={isLoading}
              style={{ pointerEvents: isLoading ? 'cursor-not-allowed' : 'auto' }}
            >
              {isLoading ? (
                <div className="flex space-x-1">
                  <span className="dot"></span>
                  <span className="dot"></span>
                  <span className="dot"></span>
                </div>
              ) : 'Sign up'}
            </button>
          </div>

          <div className="text-center">
            <p className="text-gray-600 cursor-pointer">Already have an account? <a className="text-blue-600 hover:underline" onClick={() => navigate('/login')}>Log in here</a></p>
          </div>
        </form>
      </div>

      <PrivacyPolicyModal show={showModal} handleClose={() => setShowModal(false)} />

      {/* Inline styles for the loading dots */}
      <style>
        {`
          .dot {
            height: 8px;
            width: 8px;
            margin: 0 2px;
            background-color: white;
            border-radius: 50%;
            display: inline-block;
            animation: dot-blink 1.4s infinite both;
          }
          .dot:nth-child(1) {
            animation-delay: -0.32s;
          }
          .dot:nth-child(2) {
            animation-delay: -0.16s;
          }
          @keyframes dot-blink {
            0% {
              opacity: 0.2;
              transform: scale(1);
            }
            20% {
              opacity: 1;
              transform: scale(1.2);
            }
            100% {
              opacity: 0.2;
              transform: scale(1);
            }
          }
        `}
      </style>
    </div>
  );
};

export default Register;