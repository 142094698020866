// components/RecentSignups.js
import React from 'react';

const RecentSignups = ({ signups }) => {
  return (
    <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300">
      <h2 className="text-lg md:text-xl font-bold mb-4 text-gray-800">Recent Signups</h2>
      <div className="space-y-2 md:space-y-4">
        {signups.map((signup, index) => (
          <div key={index} className="flex justify-between items-center">
            <div>
              <div className="font-bold text-gray-900">{signup.name}</div>
              <div className="text-gray-600">{signup.email}</div>
            </div>
            <div className="text-gray-600">{signup.timeAgo}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecentSignups;
