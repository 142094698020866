import React, { useState, useRef, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { AiOutlineMessage } from 'react-icons/ai';
import { urlPrefix } from '../../Settings';

const ContactUs = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [message, setMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [user, setUser] = useState({ firstName: '', lastName: '', email: '' });
  const [isFetchingUser, setIsFetchingUser] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const formRef = useRef(null);

  const userId = localStorage.getItem('userId');

  const fetchUser = async () => {
    if (!userId) return;

    setIsFetchingUser(true);
    try {
      const response = await fetch(`${urlPrefix}/User/contactUs/${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('token'),
        },
      });
      const data = await response.json();
      if (data) {
        setUser({
          firstName: data.firstName || '',
          lastName: data.lastName || '',
          email: data.email || '',
        });
      }
    } catch (error) {
      setError('Error fetching user: ' + error.message);
    } finally {
      setIsFetchingUser(false);
    }
  };

  const handleExpandClick = () => {
    if (!isExpanded) {
      fetchUser();
    }
    setIsExpanded(!isExpanded);
  };

  const validateInputs = () => {
    const errors = {};
    if (!message.trim()) {
      errors.message = 'Message is required.';
    } else if (message.length < 10) {
      errors.message = 'Message should be at least 10 characters long.';
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!validateInputs()) return;

    setIsSending(true);

    const templateParams = {
      from_name: `${user.firstName} ${user.lastName}`,
      message: message,
      reply_to: user.email,
    };

    emailjs
      .send('service_qti3qnp', 'template_s9o4pfv', templateParams, 'rX-oLTjHWoQB553DM')
      .then(() => {
        setIsSubmitted(true);
        setError('');
        setTimeout(() => {
          setIsSubmitted(false);
          setMessage('');
          setValidationErrors({});
        }, 2000);
      })
      .catch(() => {
        setError('Failed to send your message. Please try again later.');
        setTimeout(() => setError(''), 2000);
      })
      .finally(() => setIsSending(false));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        setIsExpanded(false);
      }
    };

    if (isExpanded) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isExpanded]);

  return (
    <div>
      <div
        className={`fixed bottom-6 right-6 bg-blue-700 text-white p-4 rounded-full shadow-lg cursor-pointer transition-transform duration-300 ease-in-out ${isExpanded ? 'scale-100' : 'scale-75 hover:scale-90'}`}
        onClick={handleExpandClick}
      >
        <AiOutlineMessage size={28} />
      </div>

      {isExpanded && (
        <div
          ref={formRef}
          className="fixed bottom-16 right-6 bg-white p-6 rounded-lg shadow-2xl w-80 border border-gray-300"
          style={{ zIndex: 1000 }}
        >
          <h2 className="text-2xl font-bold text-blue-700 mb-4 border-b-2 border-blue-100 pb-2">
            Contact Us
          </h2>

          {isFetchingUser ? (
            <div className="flex justify-center mb-4">
              <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"></div>
            </div>
          ) : isSubmitted ? (
            <p className="text-green-500 mb-4">Your message has been sent successfully!</p>
          ) : (
            <form onSubmit={sendEmail}>
              {error && <p className="text-red-500 mb-2">{error}</p>}

              <div className="mb-4">
                <input
                  type="text"
                  className="p-3 rounded-md w-full border bg-gray-100"
                  value={`${user.firstName} ${user.lastName}`}
                />
              </div>

              <div className="mb-4">
                <input
                  type="email"
                  className="p-3 rounded-md w-full border bg-gray-100"
                  value={user.email}
                />
              </div>

              <div className="mb-4">
                <textarea
                  className={`p-3 rounded-md w-full border ${
                    validationErrors.message ? 'border-red-500' : 'border-gray-300'
                  } focus:outline-none focus:ring-2 focus:ring-blue-500 transition-shadow duration-200`}
                  placeholder="Your Message"
                  rows="3"
                  value={message}
                  onChange={(e) => {
                    const value = e.target.value;
                    setMessage(value);

                    if (value.length >= 10) {
                      setValidationErrors((prevErrors) => {
                        const updatedErrors = { ...prevErrors };
                        delete updatedErrors.message;
                        return updatedErrors;
                      });
                    }
                  }}
                />
                {validationErrors.message && (
                  <p className="text-red-500 text-sm">{validationErrors.message}</p>
                )}
              </div>
              <button
                type="submit"
                className={`py-2 px-4 rounded-md w-full transition duration-300 ease-in-out transform hover:scale-105 ${
                  isSending ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-blue-700 hover:bg-blue-900 text-white'
                }`}
                disabled={isSending}
              >
                {isSending ? (
                  <div className="flex justify-center items-center space-x-2">
                    <span>Sending</span>
                    <div className="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full"></div>
                  </div>
                ) : (
                  'Send Message'
                )}
              </button>
            </form>
          )}
        </div>
      )}
    </div>
  );
};

export default ContactUs;
