import React from 'react';
import UserRow from './UserRow';

const UserTable = ({ users, searchTerm, dropdownOpen, toggleDropdown, isArchived = false, onUserDelete }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-300 rounded-lg">
        <thead>
          <tr className="bg-gray-200">
            <th className="py-4 px-8 border-b text-lg text-gray-800 font-bold">User</th>
            <th className="py-4 px-8 border-b text-lg text-gray-800 font-bold">Full Name</th>
            <th className="py-4 px-8 border-b text-lg text-gray-800 font-bold">Email</th>
            <th className="py-4 px-8 border-b text-lg text-gray-800 font-bold">Join Date</th>
            {isArchived && (
              <th className="py-4 px-8 border-b text-lg text-gray-800 font-bold">Deleted Date</th>
            )}
            <th className="py-4 px-8 border-b text-lg text-gray-800 font-bold">Status</th>
            <th className="py-4 px-8 border-b text-lg text-gray-800 font-bold">Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.filter(user => 
            user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.email.toLowerCase().includes(searchTerm.toLowerCase())
          ).map((user,i) => (
            <UserRow
              user={user}
              dropdownOpen={dropdownOpen}
              toggleDropdown={toggleDropdown}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserTable;
