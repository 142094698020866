import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { urlPrefix } from "../../Settings";
import SideBar from './sidebar/SideBar';
import NavBar from './NavBar';
import Users from './sidebar/user/Users';
import Classes from './sidebar/class/ClassInfo/Classes';
import AdminHome from './adminHome/AdminHome';
import DeleteClass from './sidebar/class/CRUDClass/DeleteClass'
import ClassView from './sidebar/class/ClassInfo/ClassView/ClassView'
import UsersAnalysis from './sidebar/user/UsersAnalysis';
import ArchivedUsers from './sidebar/user/ArchivedUsers';
import CreateClass from './sidebar/class/CRUDClass/CreateClass';
import EditClass from './sidebar/class/CRUDClass/EditClass';
import ClassesAnalysis from './sidebar/class/ClassesAnalysis';
import ArchivedClasses from './sidebar/class/ClassInfo/ArchivedClasses';
import NotificationSettings from './sidebar/settings/notification/NotificationSettings';
import ConfigureUi from './sidebar/settings/configure/ConfigureUiSettings';
import EditUser from '../userDashboard/EditUser';

const AdminPanel = () => {
  const handleAccountLogin = async (accountId) => {
    const token = localStorage.getItem("token");
    if (!token) return;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`
      }
    };

    try {
      const response = await fetch(`${urlPrefix}/Account/login?accountId=${accountId}`, requestOptions);
      if (response.ok) {
        const token = response.headers.get('Authorization');
        localStorage.setItem("token", token);
      } else {
        console.error('Error logging in to the account:', response.statusText);
      }
    } catch (error) {
      console.error('Error during account login:', error);
    }
  };

  useEffect(() => {
    handleAccountLogin(1);
  }, []);

  return (
    <div className="flex min-h-screen bg-gray-100">
      <SideBar />
      <div className="flex-1 flex flex-col">
        <NavBar />
        <main className="flex-1 p-2 md:p-4 lg:p-6 overflow-hidden">
          <div className="w-full h-full">
            <Routes>
              <Route path="/" element={<AdminHome />} />
              <Route path="users" element={<Users />} />
              <Route path="usersAnalysis" element={<UsersAnalysis />} />
              <Route path="archivedUsers" element={<ArchivedUsers />} />
              <Route path="classes" element={<Classes />} />
              <Route path="class-view/:id" element={<ClassView />} />
              <Route path="editClass" element={<EditClass />} />
              <Route path="createClass" element={<CreateClass />} />
              <Route path="classesAnalysis" element={<ClassesAnalysis />} />
              <Route path="archivedClasses" element={<ArchivedClasses />} />
              <Route path="editProfile" element={ <div className="-mt-11 -mb-10"><EditUser userId={localStorage.getItem("userId")}/></div>} />
              <Route path="notificationSettings" element={<NotificationSettings />} />
              <Route path="configureUi" element={<ConfigureUi />} />
              <Route path="edit-class/:id" element={<EditClass />} />
              <Route path="delete-class/:id" element={<DeleteClass />} />
            </Routes>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AdminPanel;