import React from 'react';
import { FaBook, FaCheckCircle, FaArchive } from 'react-icons/fa';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ClassAnalysis = () => {
  const totalClass = 78;
  const activeClass = 45;
  const archivedClass = 33;

  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Class Enrollments',
        data: [20, 35, 40, 55, 30, 25, 50],
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Class Enrollments Over the Past Months',
      },
    },
  };

  return (
    <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
      <h2 className="text-xl md:text-2xl font-bold mb-4">Class Analysis</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6 mb-4 md:mb-6">
        <div className="bg-gradient-to-r from-purple-500 to-purple-700 text-white p-4 md:p-6 rounded-lg shadow-md flex items-center">
          <FaBook className="text-3xl md:text-4xl mr-4" />
          <div>
            <div className="text-lg md:text-xl">Total Class</div>
            <div className="text-2xl md:text-2xl font-bold">{totalClass}</div>
          </div>
        </div>
        <div className="bg-gradient-to-r from-green-500 to-green-700 text-white p-4 md:p-6 rounded-lg shadow-md flex items-center">
          <FaCheckCircle className="text-3xl md:text-4xl mr-4" />
          <div>
            <div className="text-lg md:text-xl">Active Class</div>
            <div className="text-2xl md:text-2xl font-bold">{activeClass}</div>
          </div>
        </div>
        <div className="bg-gradient-to-r from-gray-500 to-gray-700 text-white p-4 md:p-6 rounded-lg shadow-md flex items-center">
          <FaArchive className="text-3xl md:text-4xl mr-4" />
          <div>
            <div className="text-lg md:text-xl">Archived Class</div>
            <div className="text-2xl md:text-2xl font-bold">{archivedClass}</div>
          </div>
        </div>
      </div>
      <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default ClassAnalysis;
