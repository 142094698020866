import React from 'react';
import UserRow from './UserOfClassRow'; // Assuming you have this component

const UserOfClassTable = ({ users }) => {
  return (
    <table className="min-w-full bg-white border border-[#D1D5DB] shadow-md rounded-lg">
      <thead>
        <tr>
          <th className="px-4 py-2">Image</th>
          <th className="px-4 py-2">Name</th>
          <th className="px-4 py-2">Role</th>
          <th className="px-4 py-2">Email</th>
          <th className="px-4 py-2">Created Date</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user, index) => (
          <UserRow 
            key={user.id || index}
            user={user} 
            email={user.email || "testuser@gmail.com"} // Assuming 'email' comes from user data
            role={user.role || 'Beneficiary'} // Assuming 'role' comes from user data or using default value
          />
        ))}
      </tbody>
    </table>
  );
};

export default UserOfClassTable;
