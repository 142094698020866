import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams to get the id from the URL

const benefits = [
  {
    id: 4,
    img: require('../../images/b1.webp'),
    alt: 'Increase Efficiency',
    title: 'Increase Efficiency',
    description: 'Our product streamlines operations, saving you time and resources.',
    details: [
      'Task Automation: Automate repetitive tasks to reduce manual workload and increase efficiency.',
      'Optimized Workflows: Eliminate inefficiencies by optimizing your workflows for better results.',
      'Resource Management: Effectively manage resources to ensure optimal utilization and cost reduction.',
      'Performance Monitoring: Monitor key performance indicators (KPIs) to track progress and identify areas for improvement.',
      'Process Standardization: Standardize processes across your organization to ensure consistency and quality of output.',
    ],
  },
  {
    id: 5,
    img: require('../../images/b2.webp'),
    alt: 'Enhance Collaboration',
    title: 'Enhance Collaboration',
    description: 'Improve teamwork with our integrated communication tools that foster seamless collaboration.',
    details: [
      'Instant Messaging: Allow team members to communicate in real-time, ensuring quick decision-making and problem-solving.',
      'Shared Workspaces: Create shared digital workspaces where teams can collaborate on projects, track progress, and share feedback.',
      'Collaborative Document Editing: Work on documents together in real-time, increasing collaboration and reducing errors.',
      'Video Conferencing: Facilitate virtual meetings with high-quality video and audio, allowing remote teams to stay connected.',
      'Project Management: Use our project management tools to plan, execute, and monitor the progress of your projects efficiently.',
    ],
  },
  {
    id: 6,
    img: require('../../images/b3.webp'),
    alt: 'Boost Productivity',
    title: 'Boost Productivity',
    description: 'Optimize your workflows and see a significant boost in productivity across your teams.',
    details: [
      'Task Management: Effectively manage tasks and projects using our comprehensive task management system.',
      'Workflow Optimization: Analyze and optimize current workflows to identify areas for improvement and streamline operations.',
      'Time Tracking: Track time spent on tasks and projects, helping you understand productivity patterns and make informed decisions.',
      'Performance Metrics: Monitor performance metrics to measure productivity and identify areas for improvement.',
      'Automation: Automate routine tasks to free up time for more strategic activities, resulting in higher productivity levels.',
    ],
  },
];

const LearnMoreBenefit = () => {
  const { id } = useParams(); // Get the id from the URL params
  const benefit = benefits.find(item => item.id === parseInt(id));

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component loads
  }, []);

  if (!benefit) {
    return <div>Benefit not found</div>;
  }

  return (
    <section className="p-16 mt-20 bg-gray-50 min-h-screen">
      <div className="container mx-auto">
        <h2 className="text-5xl font-bold mb-8 text-center text-blue-700">{benefit.title}</h2>
        <div className="flex flex-col md:flex-row mb-8">
          <div className="md:w-1/4">
            <img src={benefit.img} alt={benefit.alt} className="w-full h-auto rounded-lg shadow-md" />
          </div>
          <div className="md:w-1/2 md:pl-10 mt-6 md:mt-0">
            <p className="text-gray-700 text-lg">{benefit.description}</p>
            <ul className="list-disc list-inside mt-4 text-gray-700">
              {benefit.details.map((detail, index) => (
                <li key={index} className="mt-2">{detail}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LearnMoreBenefit;