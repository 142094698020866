import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component loads
  }, []);

  return (
    <div className="pt-32 relative bg-gradient-to-r from-gray-100 to-indigo-300 min-h-screen">
      <Container>
        <section className="mx-auto max-w-7xl pb-12">
          <div className="text-center mb-10">
            <h1 className="text-4xl md:text-6xl font-bold text-blue-800 leading-tight">
              Our Mission and Vision
            </h1>
          </div>

          {/* Mission Section */}
          <div className="bg-white border-t-4 border-b-4 border-blue-500 mt-10 p-8 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold text-blue-700 mb-6 text-center">
              Our Mission
            </h2>
            <p className="text-lg text-gray-700 leading-relaxed mb-6">
              Learning Mission, a non-profit organization, was founded after the
              devastating 2020 Artsakh War. Our goal is to provide free IT and
              English education to veterans, their family members, and forcibly
              displaced Artsakh residents. We equip highly motivated students
              with computers and financial support to aid their learning.
              Learning Mission fosters a supportive family atmosphere and focuses on
              students’ psychological recovery and reintegration into society.
            </p>
            <ul className="list-disc list-inside text-gray-700">
              <li>C++ Programming</li>
              <li>C# Programming</li>
              <li>HTML/CSS</li>
              <li>JavaScript</li>
              <li>UI/UX</li>
              <li>Computer Hardware Fundamentals</li>
              <li>Project Management</li>
              <li>English</li>
              <li>Armenian History, World Art, and more</li>
            </ul>
          </div>

          {/* Vision Section */}
          <div className="bg-white border-t-4 border-b-4 border-blue-500 mt-10 p-4 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold text-blue-700 mb-2 text-center">
              Our Vision
            </h2>
            <p className="text-lg text-gray-700 leading-relaxed mb-6">
              Learning Mission's vision is to become a platform where students can
              access high-quality education, secure employment, and create a bright
              future. Our main objectives include:
            </p>
            <ul className="list-disc list-inside text-gray-700">
              <li>
                Fostering educational development in the IT sphere, allowing
                students to communicate effectively in English.
              </li>
              <li>Deepening knowledge of Armenian history and culture.</li>
              <li>
                Creating a network connecting Armenia and the Armenian diaspora.
              </li>
              <li>
                Facilitating reintegration of veterans and youth into society.
              </li>
              <li>
                Expanding educational opportunities and partnerships with
                universities.
              </li>
            </ul>
          </div>
        </section>
      </Container>
    </div>
  );
};

export default AboutUs;