import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Landing from './components/landing/Landing';
import AdminPanel from './components/adminPanel/AdminPanel';
import UserDashboard from './components/userDashboard/account-components/UserDashboard';
import Login from './components/Login';
import Register from './components/Register';
import RegisterInfo from './components/RegisterInfo';
import AboutUs from "./components/landing/aboutUs/AboutUs";

function App() {
  const WithAuth = ({ children }) => {
    const token = localStorage.getItem('token');
    
    if (!token) {
      // If no token, redirect to the landing page
      return <Navigate to="/" />;
    }

    // If token exists, render the children (UserDashboard or AdminPanel)
    return children;
  };


  return (
    <Router>
      <Routes>
        {/* Public routes */}
        <Route 
          path="/" 
          element={
              <Landing />
          } 
        />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/register-info" element={<RegisterInfo />} />
        <Route path="/about-us" element={<AboutUs />} />

        {/* Protected route for UserDashboard */}
        <Route 
          path="/user/*" 
          element={
            <WithAuth>
              <UserDashboard />
            </WithAuth>
          } 
        />

        {/* Protected route for AdminPanel */}
        <Route 
          path="/admin/*" 
          element={
            <WithAuth>
              <AdminPanel />
            </WithAuth>
          } 
        />
      </Routes>
    </Router>
  );
}

export default App;



// // App.js
// import React from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import Landing from './components/landing/Landing';
// import AdminPanel from './components/adminPanel/AdminPanel';
// import UserDashboard from './components/userDashboard/account-components/UserDashboardV2'
// import Login from './components/Login';
// import Register from './components/Register';
// import RegisterInfo from './components/RegisterInfo';
// import AboutUs from "./components/landing/aboutUs/AboutUs";

// function App() {
// const WithAuth = ({ children }) => {
//   const token = localStorage.getItem('token');

//   if (!token) {
//     // If no token, redirect to the landing page
//     return <Navigate to="/" />;
//   }
//   // If token exists, render the children (UserDashboard)
//   return children;
// };

//   return (
//     <Router>
//       <Routes>
//         {/* Public routes */}
//         {/* <Route path="/" element={<Home />} /> */}
//           <Route path="/login" element={<Login />} />
//           <Route path="/register" element={<Register />} />
//           <Route path="/register-info" element={<RegisterInfo />} />
//         {/* AdminPanel routes */}
//         <Route path="/about-us" element={<AboutUs />} />

//         <Route path="/*" element={
//           <Landing />}/>
//   {/* Protected route for UserDashboard */}
//   <Route 
//           path="/user/*" 
//           element={
//             <WithAuth>
//               <UserDashboard />
//             </WithAuth>
//           } 
//         />
//           <Route path="/admin/*" element={
//             <WithAuth>
//             <AdminPanel />
//           </WithAuth>
//             } />

//       </Routes>
//     </Router>
//   );
// }

// export default App;