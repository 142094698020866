import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';

const PasswordUpdateModal = ({ isOpen, onClose, urlPrefix }) => {
    const [modalData, setModalData] = useState({ currentPassword: '', newPassword: '', confirmPassword: '' });
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setModalData({ currentPassword: '', newPassword: '', confirmPassword: '' });
            setErrors({});
            setSuccessMessage('');
            setIsSubmitting(false);
        }
    }, [isOpen]);

    const validatePassword = (password) => {
        return password.length >= 6;
    };

    const handleModalChange = (e) => {
        const { name, value } = e.target;
        setModalData(prevState => {
            const updatedModalData = { ...prevState, [name]: value };
            const newErrors = { ...errors };

            // Check if the new password and confirm password match
            if (updatedModalData.newPassword !== updatedModalData.confirmPassword) {
                newErrors.confirmPassword = "Passwords do not match.";
            } else {
                delete newErrors.confirmPassword;
            }

            // Validate the new password length
            if (name === 'newPassword' && !validatePassword(updatedModalData.newPassword)) {
                newErrors.newPassword = "Password must be at least 6 characters long.";
            } else {
                delete newErrors.newPassword;
            }

            setErrors(newErrors);
            return updatedModalData;
        });
    };

    const handleModalSubmit = async () => {
        // Ensure there are no field-specific errors before submitting
        if (Object.keys(errors).filter(key => key !== 'form').length > 0) return;
    
        setIsSubmitting(true); // Set loading state
        setSuccessMessage(""); // Clear any previous messages

        try {
            const response = await fetch(`${urlPrefix}/User/modifyPassword`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")
                },
                body: JSON.stringify({
                    OldPassword: modalData.currentPassword,
                    NewPassword: modalData.newPassword,
                }),
            });

            if (response.ok) {
                setSuccessMessage('Password updated successfully!');
                setErrors({});
                setTimeout(() => {
                    setSuccessMessage("");
                    onClose(); // Close modal after 2 seconds
                }, 1000);
            } else {
                const errorData = await response.json();
                setErrors({ form: errorData.message || "Error updating password. Please check your credentials and try again." });
                setIsSubmitting(false);
                setTimeout(() => {
                    setErrors("");
                }, 1000)
            }
        } catch (error) {
            setErrors({ form: "An unexpected error occurred. Please try again later." });
            setIsSubmitting(false);
            setTimeout(() => {
                setErrors("");
            }, 1000)
        }
    };

    const hasErrors = Object.keys(errors).filter(key => key !== 'form').length > 0;
    const isAnyFieldEmpty = !modalData.currentPassword || !modalData.newPassword || !modalData.confirmPassword;

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className="bg-white p-8 rounded-xl shadow-2xl w-full max-w-xl h-auto min-h-[400px] mx-auto mt-20 z-50 transform transition-transform duration-300 scale-95"
            overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-50 z-40 flex items-center justify-center"
        >
            <h2 className="text-3xl font-bold text-gray-900 mb-6">Update Password</h2>
            {successMessage && (
                <div className="bg-green-100 text-green-700 p-3 rounded-lg mb-4 flex items-center">
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                    {successMessage}
                </div>
            )}
            {errors.form && (
                <div className="bg-red-100 text-red-700 p-3 rounded-lg mb-4">
                    <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
                    {errors.form}
                </div>
            )}
            <div className="space-y-4">
                <label className="block font-bold">
                    Current Password
                    <input
                        type="password"
                        name="currentPassword"
                        value={modalData.currentPassword}
                        onChange={handleModalChange}
                        placeholder="Enter your current password"
                        className={`mt-1 p-3 border rounded-lg w-full transition-all duration-300 ease-in-out ${
                            errors.currentPassword ? 'border-red-500' : 'border-gray-300'
                        }`}
                    />
                </label>
                <label className="block font-bold">
                    New Password
                    <input
                        type="password"
                        name="newPassword"
                        value={modalData.newPassword}
                        onChange={handleModalChange}
                        placeholder="Enter your new password"
                        className={`mt-1 p-3 border rounded-lg w-full transition-all duration-300 ease-in-out ${
                            errors.newPassword ? 'border-red-500' : 'border-gray-300'
                        }`}
                    />
                    {errors.newPassword && <span className="text-red-500 text-sm">{errors.newPassword}</span>}
                </label>
                <label className="block font-bold">
                    Confirm Password
                    <input
                        type="password"
                        name="confirmPassword"
                        value={modalData.confirmPassword}
                        onChange={handleModalChange}
                        placeholder="Confirm your new password"
                        className={`mt-1 p-3 border rounded-lg w-full transition-all duration-300 ease-in-out ${
                            errors.confirmPassword ? 'border-red-500' : 'border-gray-300'
                        }`}
                    />
                    {errors.confirmPassword && <span className="text-red-500 text-sm">{errors.confirmPassword}</span>}
                </label>
            </div>
            <div className="flex justify-end space-x-4 mt-6">
                <button
                    className={`bg-blue-500 text-white p-3 rounded-lg shadow-md transition-all duration-300 ease-in-out hover:bg-blue-600 hover:shadow-lg ${
                        (hasErrors && !errors.form) || isAnyFieldEmpty || isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    onClick={handleModalSubmit}
                    disabled={(hasErrors && !errors.form) || isAnyFieldEmpty || isSubmitting}
                >
                    {isSubmitting ? (
                        <span className="flex items-center space-x-2">
                            <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
                            <span>Updating...</span>
                        </span>
                    ) : (
                        'Update Password'
                    )}
                </button>
                <button
                    className="bg-gray-500 text-white p-3 rounded-lg shadow-md transition-all duration-300 ease-in-out hover:bg-gray-600 hover:shadow-lg"
                    onClick={onClose}
                >
                    Cancel
                </button>
            </div>
        </Modal>
    );
};

export default PasswordUpdateModal;