import React from 'react';
import { useNavigate } from 'react-router-dom';
import b1 from '../../images/b1.webp';
import b2 from '../../images/b2.webp';
import b3 from '../../images/b3.webp';

const benefits = [
  {
    id: 4,
    img: b1,
    title: 'Increase Efficiency',
    description: 'Our product streamlines operations, saving you time and resources.',
  },
  {
    id: 5,
    img: b2,
    title: 'Enhance Collaboration',
    description: 'Improve teamwork with our integrated communication tools.',
  },
  {
    id: 6,
    img: b3,
    title: 'Boost Productivity',
    description: 'Optimize your workflows and see a significant boost in productivity.',
  },
];

const Benefits = () => {
  const navigate = useNavigate(); // Use navigate for routing

  return (
    <section id="benefits" className="p-20">
      <h2 className="text-3xl font-bold mb-8 text-center">Benefits</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {benefits.map((benefit) => (
          <div
            key={benefit.id}
            className="p-6 rounded-lg shadow-lg hover:shadow-xl flex flex-col justify-between hover:bg-slate-100 transition-transform duration-300 transform hover:-translate-y-2"
          >
            <div>
              <img src={benefit.img} alt={benefit.title} className="mb-4 w-full h-80 object-cover rounded" />
              <h3 className="text-xl font-bold mb-2">{benefit.title}</h3>
              <p>{benefit.description}</p>
            </div>
            <button
              onClick={() => navigate(`/learn-more-benefit/${benefit.id}`)} // Use navigate to go to the benefit page
              className="text-blue-500 hover:underline mt-4 self-end"
            >
              Learn More <i className="bi-chevron-right small ms-1"></i>
            </button>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Benefits;