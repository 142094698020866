import React from "react";
import { Link } from "react-router-dom";

const ClassHeader = ({ className, usersCount, accountManager, accountAdmin, onboardingLink }) => {
  return (
    <div className="mb-6  text-[#000000]">
      <h1 className="text-4xl font-bold mb-4 ">{className}</h1>
      <div className="grid grid-cols-4 gap-4">
        <div className="p-4 bg-[#FFFFFF] border border-[#D1D5DB] shadow-md rounded-lg text-center">
          <h3 className="text-lg font-medium">Users Count</h3>
          <p className="text-3xl font-semibold">{usersCount}</p>
        </div>
        <div className="p-4 bg-[#FFFFFF] border border-[#D1D5DB] shadow-md rounded-lg text-center">
          <h3 className="text-lg font-medium">Account Manager</h3>
          <p className="text-xl">{accountManager}</p>
        </div>
        <div className="p-4 bg-[#FFFFFF] border border-[#D1D5DB] shadow-md rounded-lg text-center">
          <h3 className="text-lg font-medium">Account Admin</h3>
          <p className="text-xl">{accountAdmin}</p>
        </div>
        <div className="p-4 bg-[#FFFFFF] border border-[#D1D5DB] shadow-md rounded-lg text-center">
          <h3 className="text-lg font-medium">Onboarding Link</h3>
          <a href={onboardingLink} target="_blank" rel="noopener noreferrer" className="text-base text-[#153cff]">
            {onboardingLink}
          </a>   
          </div>
      </div>
    </div>
  );
};

export default ClassHeader;
