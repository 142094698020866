import React, { useState, useEffect } from 'react';
import { FaTimesCircle } from 'react-icons/fa';
import { urlPrefix } from '../../../../Settings';

const AboutUser = ({ isOpen, onClose, userId }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(`${urlPrefix}/User/${userId}`, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        });
        const data = await response.json();
        if (data) {
          setUser(data);
        } else {
          console.error("Unexpected data format:", data);
        }
      } catch (error) {
        console.error("Error fetching user:", error);
      } finally {
        setLoading(false);
      }
    };
    
    if (userId) {
      fetchUser();
    }
  }, [userId]);

  if (!isOpen) return null;

  if (loading) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white rounded-lg shadow-lg w-1/2 p-6 relative">
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white rounded-lg shadow-lg w-1/2 p-6 relative">
          <p>User not found.</p>
        </div>
      </div>
    );
  }

  const renderGender = (gender) => {
    switch (gender) {
      case 1:
        return 'Male';
      case 2:
        return 'Female';
      default:
        return 'N/A';
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-1/2 p-6 relative">
        {/* Close button */}
        <button
          className="absolute top-4 right-4 text-gray-500"
          onClick={onClose}
        >
          <FaTimesCircle />
        </button>

        {/* User Information */}
        <h2 className="text-xl font-bold mb-4">{user.firstName} {user.lastName}</h2>
        <p><strong>Email:</strong> {user.email}</p>
        <p><strong>Status:</strong> {user.statusId === 2 ? "Active" : user.statusId === 1 ? "Pending" : "Deleted"}</p>
        <p><strong>Phone Number:</strong> {user.phoneNumber || "N/A"}</p>
        <p><strong>Middle Name:</strong> {user.middleName || "N/A"}</p>
        <p><strong>Gender:</strong> {renderGender(user.gender)}</p>
        <p><strong>Date of Birth:</strong> {user.dateOfBirth ? new Date(user.dateOfBirth).toLocaleDateString() : "N/A"}</p>
        <p><strong>Date Created:</strong> {user.createDate ? new Date(user.createDate).toLocaleDateString() : "N/A"}</p>
        <p><strong>Last Updated:</strong> {user.updateDate ? new Date(user.updateDate).toLocaleDateString() : "N/A"}</p>
        <p><strong>Metadata:</strong> {user.metadata || "N/A"}</p>

        {/* Display user image if available */}
        {user.userImage && (
          <div className="mt-4">
            <img
              src={user.userImage}
              alt={`${user.firstName} ${user.lastName}`}
              className="w-32 h-32 rounded-full object-cover"
            />
          </div>
        )}

        {/* Close Button */}
        <div className="mt-4">
          <button onClick={onClose} className="bg-blue-500 text-white py-2 px-4 rounded">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default AboutUser;