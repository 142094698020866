import React, { useEffect } from 'react';

const LearnMoreDiscoverMore = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component loads
  }, []);

  return (
    <section className="px-10 pt-16 -mb-28 mt-16 bg-gray-50 min-h-screen">
      <div className="container mx-auto">
        <h2 className="text-5xl font-bold mb-8 text-center text-blue-700">Discover More</h2>
        <p className="text-lg mb-8 text-gray-700">
          Our product offers a range of features that can greatly benefit your business, making your operations more efficient, improving team collaboration, and boosting overall productivity. Whether you are in a small team or a large organization, our customizable tools can help streamline your processes and drive success.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-2xl font-semibold mb-4">Feature 1: Streamlined Operations</h3>
            <p className="text-gray-700">
              Our product helps automate repetitive tasks and optimize workflows, allowing your team to focus on higher-value activities. With real-time monitoring and resource management tools, you can ensure that your business runs smoothly and efficiently.
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-2xl font-semibold mb-4">Feature 2: Enhanced Collaboration</h3>
            <p className="text-gray-700">
              Our communication tools make it easier for teams to collaborate, whether they are working in the same office or remotely. From instant messaging to shared workspaces and document editing, we make it easy for your team to stay connected and productive.
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-2xl font-semibold mb-4">Feature 3: Data-Driven Insights</h3>
            <p className="text-gray-700">
              With advanced analytics and reporting tools, you can gain valuable insights into your business performance. Use our real-time dashboards to track key metrics, identify trends, and make data-driven decisions that propel your business forward.
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-2xl font-semibold mb-4">Feature 4: Scalability</h3>
            <p className="text-gray-700">
              Whether your business is growing or changing, our solution is designed to scale with you. We offer customizable workflows and integrations to support your evolving needs, ensuring that your business continues to run efficiently as it grows.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LearnMoreDiscoverMore;