import React, { useState } from 'react';

const ClassInfo = ({
  initialImage = '',
  initialTitle = '',
  initialDescription = '',
  initialLink = { url: '', label: '' },
  initialCapacity = 0,
  initialVisibility = true,
  handleAction
}) => {
  const [classTitle, setClassTitle] = useState(initialTitle);
  const [classDescription, setClassDescription] = useState(initialDescription);
  const [classLink, setClassLink] = useState(initialLink);
  const [classCapacity, setClassCapacity] = useState(initialCapacity);
  const [classVisible, setClassVisible] = useState(initialVisibility);
  const [photoURL, setPhotoURL] = useState(initialImage);

  const handleLinkChange = (event) => {
    setClassLink({ ...classLink, [event.target.name]: event.target.value });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPhotoURL(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeletePhoto = () => {
    setPhotoURL('');
  };

  const handleSaveClass = () => {
    const classData = {
      classTitle,
      classDescription,
      classLink,
      classCapacity,
      classVisible,
      photoURL,
    };
    handleAction(classData);
  };

  return (
    <div className="bg-[#fcfcfc] container mx-auto max-w-full px-8 py-12 rounded-lg shadow-2xl">
      <h1 className="mb-8 text-4xl font-extrabold text-[#3a3a3a]">Create Class</h1>
      <div className="flex space-x-10">
        <div className="duration-300 ease-in-out w-1/3 rounded-lg">
          {photoURL ? (
            <div className="relative">
              <img
                src={photoURL}
                alt="Class"
                className="w-full h-60 object-cover rounded-t-lg"
              />
              <button
                type="button"
                onClick={handleDeletePhoto}
                className="absolute top-3 right-3 bg-[#b91717] text-[#000000] py-2 px-5 rounded-full hover:bg-[#ff2f2f] transition-colors duration-300 shadow-lg"
              >
                Delete
              </button>
            </div>
          ) : (
            <div className="relative flex flex-col items-center justify-center h-60">
              <div className="absolute inset-0 bg-[#151515] opacity-40 rounded-lg"></div>
              <svg
                fill="#b0b0b0"
                height="120px"
                width="120px"
                viewBox="-48.95 -48.95 587.42 587.42"
              >
                <g>
                  <g>
                    <path d="M468.286,144.662l-16.5-5.7l-6.6-47.8c-3.8-27.1-26.4-47.4-53.7-48.3c-3.2-0.1-6.3,0.1-9.5,0.5l-146.3,20.4l-103.9-36.2 c-16.5-5.8-34.6,3-40.4,19.5l-13.4,38.6l-20,2.8c-14.8,2.1-27.9,9.8-37,21.7c-9,11.9-12.9,26.7-10.8,41.5l12.8,92l-21.2,60.8 c-5.8,16.5,3,34.6,19.5,40.4l16.5,5.7l6.6,47.8c3.8,27.1,26.3,47.4,53.7,48.3c3.2,0.1,6.3-0.1,9.5-0.5l146.3-20.4l103.9,36.2 c16.5,5.8,34.6-3,40.4-19.5l13.4-38.5l20-2.8c30.6-4.2,52-32.6,47.7-63.1l-12.8-92.1l21.2-60.8 C493.586,168.562,484.786,150.462,468.286,144.662z M59.686,139.362c0.8-1.1,2.4-2.5,4.9-2.9l324.1-45c0.4-0.1,0.9-0.1,1.3-0.1 c3.1,0.1,6.6,2.3,7.1,6.4l25.6,184.6l-68.8-52.1c-6.8-5.2-16.6-3.8-21.8,3l-43.9,58l-102.2-77.4c-6.8-5.2-16.6-3.8-21.8,3 l-81.1,107.2l-24.9-179.2C57.886,142.362,58.786,140.462,59.686,139.362z"></path>
                    <circle cx="280.186" cy="170.362" r="36.8"></circle>
                  </g>
                </g>
              </svg>
              <label
                htmlFor="classPhoto"
                className="absolute inset-0 flex flex-col items-center justify-center rounded-lg text-[#d0d0d0] font-semibold cursor-pointer transition-all duration-200 hover:bg-opacity-30 hover:backdrop-blur-sm"
              >
                <span className="text-lg font-bold">Upload Photo</span>
                <input
                  type="file"
                  onChange={handleFileChange}
                  className="hidden"
                  id="classPhoto"
                />
              </label>
            </div>
          )}
        </div>
        <div className="flex flex-col justify-between w-2/3 space-y-6">
          <div>
            <label
              className="mb-4 text-xl font-bold text-[#3a3a3a]"
              htmlFor="title"
            >
              Class Title
            </label>
            <input
              className="flex h-12 w-full rounded-md border border-[#a0a0a0] bg-[#e0e0e0] text-[#3a3a3a] px-4 py-2 text-md placeholder-[#8a8a8a] focus:outline-none focus:ring-2 focus:ring-[#5a5a5a]"
              id="title"
              placeholder="Enter class title"
              type="text"
              value={classTitle}
              onChange={(e) => setClassTitle(e.target.value)}
            />
          </div>
          <div>
            <label
              className="mb-4 text-xl font-bold text-[#3a3a3a]"
              htmlFor="description"
            >
              Class Description
            </label>
            <textarea
              className="flex w-full rounded-md border border-[#a0a0a0] bg-[#e0e0e0] text-[#3a3a3a] px-4 py-2 text-md placeholder-[#8a8a8a] focus:outline-none focus:ring-2 focus:ring-[#5a5a5a] h-32"
              id="description"
              placeholder="Enter class description"
              value={classDescription}
              onChange={(e) => setClassDescription(e.target.value)}
            ></textarea>
          </div>
          <div>
            <h2 className="mb-4 text-xl font-bold text-[#3a3a3a]">Class Links</h2>
            <div className="flex flex-col gap-6 mb-6">
              <input
                className="h-12 w-full rounded-md border border-[#a0a0a0] bg-[#e0e0e0] text-[#3a3a3a] px-4 py-2 text-md placeholder-[#8a8a8a] focus:outline-none focus:ring-2 focus:ring-[#5a5a5a]"
                placeholder="Link Label"
                type="text"
                name="label"
                value={classLink.label}
                onChange={handleLinkChange}
              />
              <input
                className="h-12 w-full rounded-md border border-[#a0a0a0] bg-[#e0e0e0] text-[#3a3a3a] px-4 py-2 text-md placeholder-[#8a8a8a] focus:outline-none focus:ring-2 focus:ring-[#5a5a5a]"
                placeholder="Link URL"
                type="text"
                name="url"
                value={classLink.url}
                onChange={handleLinkChange}
              />
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div className="w-1/2 pr-8">
              <label
                className="mb-4 text-xl font-bold text-[#3a3a3a]"
                htmlFor="capacity"
              >
                Class Capacity
              </label>
              <input
                className="flex h-12 w-full rounded-md border border-[#a0a0a0] bg-[#e0e0e0] text-[#3a3a3a] px-4 py-2 text-md placeholder-[#8a8a8a] focus:outline-none focus:ring-2 focus:ring-[#5a5a5a]"
                id="capacity"
                min="0"
                type="number"
                value={classCapacity}
                onChange={(e) => setClassCapacity(e.target.value)}
              />
            </div>
            <div className="flex items-center w-1/2 pl-8">
              <label className="switch flex items-center">
                <input
                  type="checkbox"
                  checked={classVisible}
                  onChange={(e) => setClassVisible(e.target.checked)}
                  className="checkbox hidden"
                />
                <div className="slider relative w-14 h-7 bg-[#727272] rounded-full cursor-pointer transition-all duration-300">
                  <div className={`absolute left-0 top-0 bottom-0 bg-white w-7 h-7 rounded-full transition-all duration-300 transform ${classVisible ? 'translate-x-7 bg-[#5a5a5a]' : 'translate-x-0'}`}></div>
                </div>
              </label>
              <label htmlFor="visibility" className="ml-3 mb-4 text-xl font-bold text-[#3a3a3a]">
                Class Visible
              </label>
            </div>
          </div>
          <button
            className="mt-6 inline-flex items-center justify-center rounded-lg bg-[#727272] text-[#ffffff] text-lg font-medium h-12 px-8 py-3 hover:bg-[#5c5c5c] transition-all duration-200 shadow-lg"
            onClick={handleSaveClass}
          >
            Save Class
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClassInfo;
