import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { urlPrefix } from "../Settings";
import ResetPasswordModal from './ResetPasswordModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    setEmailError('');
    setPasswordError('');
    setError('');

    if (!email) {
      setEmailError('Email is required.');
      return;
    }

    if (!password) {
      setPasswordError('Password is required.');
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch(`${urlPrefix}/User/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ Email: email, Password: password }),
      });

      if(response.status === 428)
      {
        const token = response.headers.get('Authorization');
        localStorage.setItem("token", token);
        if (!rememberMe) {
          sessionStorage.setItem("token", token);
        }
        const result = await response.json();
        if (result) {
          localStorage.setItem("userId", JSON.stringify(result.id));
          if (!rememberMe) {
            sessionStorage.setItem("userId", JSON.stringify(result.id));
          }          
          navigate('/register-info'); // Navigate to user dashboard
        }
      }

      if (response.status === 200) {
        const token = response.headers.get('Authorization');
        localStorage.setItem("token", token);
        if (!rememberMe) {
          sessionStorage.setItem("token", token);
        }
        const result = await response.json();
        if (result) {
          localStorage.setItem("userId", JSON.stringify(result.id));
          if (!rememberMe) {
            sessionStorage.setItem("userId", JSON.stringify(result.id));
          }

          const accountResponse = await fetch(`${urlPrefix}/Account/users/accounts`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`
            }
          });

          if (accountResponse.ok) {
            const accounts = await accountResponse.json();
            const account = accounts.find(acc => acc.id === 1 && acc.accountRoleId === 2);

            if (account) {
              navigate('/admin');  // Navigate to admin dashboard
              return;
            }
          }

          navigate('/user'); // Navigate to user dashboard
        } else {
          setError("Authentication error. Please check your email or password and try again.");
          setTimeout(() => setError(""), 2000)
        }
      } else {
        setError("Authentication error. Please check your email or password and try again.");
        setTimeout(() => setError(""), 2000)
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Error logging in. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleLogin = () => {
    window.location.href = `${urlPrefix}/auth/google`;
  };

  const handleForgotPasswordClick = () => {
    setShowResetPasswordModal(true);
  };

  const handleCloseModal = () => {
    setShowResetPasswordModal(false);
  };

  return (
    <div className="bg-gradient-to-r from-gray-100 to-indigo-300 min-h-screen flex items-center justify-center relative">
      {/* Back Button */}
      <div className="flex justify-start mb-7">
        <button 
          onClick={() => navigate('/')}  // Use navigate for back button
          className="absolute top-4 left-4 flex items-center text-blue-600 hover:text-blue-800 font-semibold text-lg">
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" /> Back to Home
        </button>
      </div>
      <div className="bg-white p-8 rounded-lg shadow-2xl w-full max-w-md">
        <div className="text-center mb-6">
          <h1 className="text-3xl font-bold text-blue-600">Welcome back</h1>
          <p className="text-blue-600 ">Login to manage your account.</p>
        </div>

        <form onSubmit={handleSubmit} noValidate>
          {error && (
            <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-lg">
              <i className="fas fa-exclamation-circle"></i> {error}
            </div>
          )}
          <div className="mb-4">
            <label className="block mb-2 text-gray-700" htmlFor="signupModalFormLoginEmail">Your email</label>
            <input
              type="email"
              className={`w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${emailError ? 'border-red-500' : 'border-gray-300'}`}
              name="email"
              id="signupModalFormLoginEmail"
              placeholder="email@site.com"
              aria-label="email@site.com"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailError && <div className="text-red-500 text-sm mt-1">{emailError}</div>}
          </div>

          <div className="mb-4">
            <label className="block mb-2 text-gray-700" htmlFor="signupModalFormLoginPassword">Password</label>

            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                className={`w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${passwordError ? 'border-red-500' : 'border-gray-300'}`}
                name="password"
                id="signupModalFormLoginPassword"
                placeholder="8+ characters required"
                aria-label="8+ characters required"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 px-3 flex items-center focus:outline-none"
                onClick={() => setShowPassword(!showPassword)}
              >
                <i className={showPassword ? 'bi bi-eye text-gray-600' : 'bi bi-eye-slash text-gray-600'}></i>
              </button>
            </div>
            <div className="flex justify-between items-center mt-2">
              <div className="flex items-center space-x-2 whitespace-nowrap">
                <input
                  className="form-checkbox text-blue-600 focus:ring-blue-500"
                  type="checkbox"
                  id="rememberMe"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                <label className="text-gray-700 mt-3" htmlFor="rememberMe">
                  Remember me
                </label>
              </div>
            </div>
            {passwordError && <div className="text-red-500 text-sm mt-1">{passwordError}</div>}
          </div>

          <div className="mb-4">
            <button 
              type="submit" 
              className={`w-full p-3 rounded-lg transition duration-200 flex justify-center items-center 
                ${isLoading ? 'bg-blue-300 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700 text-white'}`}
              disabled={isLoading}
              style={{ pointerEvents: isLoading ? 'cursor-not-allowed' : 'auto' }}
            >
              {isLoading ? (
                <div className="flex space-x-1">
                  <span className="dot"></span>
                  <span className="dot"></span>
                  <span className="dot"></span>
                </div>
              ) : 'Log in'}
            </button>
          </div>

          <div className="text-center">
            <p className="text-gray-700 cursor-pointer">Don't have an account yet? <a className="text-blue-600 hover:underline" onClick={() => navigate('/register')}>Sign up here</a></p>
          </div>
        </form>
      </div>

      <ResetPasswordModal showModal={showResetPasswordModal} handleClose={handleCloseModal} />

      {/* Inline styles for the loading dots */}
      <style>
        {`
          .dot {
            height: 8px;
            width: 8px;
            margin: 0 2px;
            background-color: white;
            border-radius: 50%;
            display: inline-block;
            animation: dot-blink 1.4s infinite both;
          }
          .dot:nth-child(1) {
            animation-delay: -0.32s;
          }
          .dot:nth-child(2) {
            animation-delay: -0.16s;
          }
          @keyframes dot-blink {
            0% {
              opacity: 0.2;
              transform: scale(1);
            }
            20% {
              opacity: 1;
              transform: scale(1.2);
            }
            100% {
              opacity: 0.2;
              transform: scale(1);
            }
          }
        `}
      </style>
    </div>
  );
};

export default Login;