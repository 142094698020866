import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUserCircle, faBell, faHome, faUser, faSignOutAlt, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { urlPrefix } from '../Settings';
import logo from '../../src/style/Image/LearningLogo.png';

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState({});
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate for routing
  const token = localStorage.getItem('token');
  const fetchUser = async () => {
    if (!userId) return;

    try {
      const response = await fetch(`${urlPrefix}/User/${userId}`, {
        method: "GET",
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`
      },
      });
      const data = await response.json();
      if (data) {
        setUser(data);
      } else {
        console.error("Unexpected data format:", data);
      }
    } catch (error) {
      console.error("Error fetching user:", error);
      setError("Error fetching user: " + error.message);
    }
  };

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    if (storedUserId) {
      setIsLoggedIn(true);
      setUserId(JSON.parse(storedUserId));
    }
  }, []);

  useEffect(() => {
    fetchUser();
  }, [userId]);

  const handleLogout = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("token");
    localStorage.removeItem('selectedAccountId');
    setIsLoggedIn(false);
    setUserId(null);
    navigate('/'); // Navigate to home after logout
  };

  const handleProfileClick = () => {
    navigate('/user/edit-profile'); // Navigate to the profile page
  };

  return (
    <header className="navbar navbar-expand-lg navbar-light bg-light fixed-top w-full z-50 transition-colors duration-300 ease-in-out shadow-md border-b border-gray-300">
      <div className="container mx-auto flex items-center justify-between">
        <div className="flex items-center">
          {/* Logo */}
          <img src={logo} alt="Site Logo" className="h-52 w-60 -mt-14 -mb-20 mr-2" />
        </div>

        {/* Right-side items */}
        <ul className="navbar-nav flex items-center ml-auto space-x-4">
          {isLoggedIn && (
            <li className="nav-item">
              <a className="nav-link text-gray-600 text-lg transition-colors duration-300 ease-in-out hover:text-blue-600 cursor-pointer" onClick={() => navigate('/user')}>
                <FontAwesomeIcon icon={faHome} className="mr-2" /> Home
              </a>
            </li>
          )}

          {isLoggedIn ? (
            <>
              <li className="nav-item dropdown">
                <a className="nav-link flex items-center cursor-pointer" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <img src={user.userImage} alt="User Profile" className="mr-2 rounded-full w-10 h-10" /> {user.firstName}
                </a>
                <ul className="dropdown-menu border-t-2 border-blue-600 shadow-md mt-2 transition-opacity duration-300 ease-in-out">
                  <li>
                    <a className="dropdown-item text-gray-600 py-2 px-4 transition-colors duration-300 ease-in-out hover:text-blue-600 hover:bg-gray-200 rounded-md cursor-pointer" onClick={handleProfileClick}>
                      <FontAwesomeIcon icon={faUser} className="mr-2" /> Profile
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item text-gray-600 py-2 px-4 transition-colors duration-300 ease-in-out hover:text-blue-600 hover:bg-gray-200 rounded-md cursor-pointer" onClick={handleLogout}>
                      <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" /> Logout
                    </a>
                  </li>
                </ul>
              </li>
            </>
          ) : (
            <>
              <li className="nav-item login mr-2">
                <button className="btn btn-outline-primary text-blue-600 border-blue-600 transition-colors duration-300 ease-in-out hover:bg-blue-600 hover:text-white" onClick={() => navigate('/login')}>
                  Login
                </button>
              </li>
              <li className="nav-item">
                <button className="group btn btn-outline-primary bg-blue-600 text-white border-blue-600 transition-colors duration-300 ease-in-out hover:bg-white">
                  <span className="transition-colors duration-300 ease-in-out group-hover:text-blue-600" onClick={() => navigate('/register')}>
                    Signup
                  </span>
                </button>
              </li>
            </>
          )}
        </ul>
      </div>
    </header>
  );
};

export default Navbar;