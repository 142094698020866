import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from '../firebase/firebaseClient';
import { urlPrefix } from "../../Settings";
import EmailUpdateModal from '../userEdit/EmailUpdateModal';
import PasswordUpdateModal from '../userEdit/PasswordUpdateModal';
import '@fortawesome/fontawesome-free/css/all.min.css';

const EditUser = ({ userId }) => {
  const [user, setUser] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: "",
    gender: "",
    dateOfBirth: new Date(),
    requestedUserRoleId: null,
    statusId: null,
    disableAds: false,
    userImage: "",
    bio: "",
  });

  // const [storedUserId, setUserId] = useState(null); // State to store the userId from localStorage
  const [message, setMessage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [modalData, setModalData] = useState({ currentEmail: "", newEmail: "", password: "" });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false); // Loading state for saving changes
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const dropdownRef = useRef(null);

  const fetchUser = async (userIdToEdit) => {
    if (!userIdToEdit) return;

    try {
      const response = await fetch(`${urlPrefix}/User/${userIdToEdit}`, {
        method: "GET",
        headers: { "Content-Type": "application/json", "Authorization": localStorage.getItem("token") },
      });
      const data = await response.json();
      if (data) {
        let date = new Date(data.dateOfBirth);
        setUser({
          firstName: data.firstName || "",
          middleName: data.middleName || "",
          lastName: data.lastName || "",
          email: data.email || "",
          phone: data.phoneNumber || "",
          gender: data.gender?.toString() || "",
          dateOfBirth: date,
          requestedUserRoleId: data.requestedUserRoleId || null,
          statusId: data.statusId || null,
          disableAds: false,
          userImage: data.userImage || "",
          bio: data.metadata || "", // Mapping 'metadata' to 'bio'
        });
        setImageUrl(data.userImage || "");
      } else {
        console.error("Unexpected data format:", data);
      }
    } catch (error) {
      console.error("Error fetching user:", error);
      setMessage("Error fetching user: " + error.message);
    }
  };

  useEffect(() => {
    if (!userId) return;
    fetchUser(userId)
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    if (showOptions) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showOptions]);

  const validateForm = () => {
    let validationErrors = {};

    if (!user.firstName.trim()) {
      validationErrors.firstName = "First Name is required";
    }
    if (!user.lastName.trim()) {
      validationErrors.lastName = "Last Name is required";
    }
    if (!user.phone.trim()) {
      validationErrors.phone = "Phone number is required";
    } else if (!/^\+?\d{10,15}$/.test(user.phone)) {
      validationErrors.phone = "Invalid phone number";
    }
    if (!user.gender) {
      validationErrors.gender = "Gender is required";
    }
    if (!user.dateOfBirth) {
      validationErrors.dateOfBirth = "Date of Birth is required";
    }
    if (user.bio.length > 1000) {
      validationErrors.bio = "Bio should not exceed 1000 characters";
    }    
       setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === 'dateOfBirth') {
      const dateObject = new Date(value);
      if (!isNaN(dateObject.getTime())) {
        setUser({
          ...user,
          [name]: dateObject,
        });
      } else {
        console.error("Invalid date:", value);
      }
    } else {
      setUser({
        ...user,
        [name]: type === 'checkbox' ? checked : value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsLoading(true); // Set loading to true
    if (!userId) return;
    try {
      const response = await fetch(`${urlPrefix}/User/userInfo`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": localStorage.getItem("token")
        },
        body: JSON.stringify({
          id: userId,
          statusId: user.statusId,
          firstName: user.firstName,
          middleName: user.middleName,
          requestedUserRoleId: 1,
          lastName: user.lastName,
          phoneNumber: user.phone,
          gender: parseInt(user.gender, 10),
          dateOfBirth: user.dateOfBirth,
          metadata: user.bio, // Mapping 'bio' to 'metadata'
          userImage: user.userImage, // Save profile photo URL
        }),
      });
      if (response.ok) {
        setSuccessMessage('User Info Saved Successfully!');
        setErrorMessage(""); // Clear any previous error message
      } else {
        setErrorMessage('Error saving user info');
        setSuccessMessage(""); // Clear any previous success message
      }
    } catch (error) {
      console.error('Error updating user info:', error);
      setErrorMessage('Error updating user info: ' + error.message);
      setSuccessMessage(""); // Clear any previous success message
    } finally {
      setIsLoading(false); // Reset loading state
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 2000); // Hide messages after 2 seconds
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const storageRef = ref(storage, `profilePhotos/${file.name}`);
      try {
        const snapshot = await uploadBytes(storageRef, file);
        const fileUrl = await getDownloadURL(snapshot.ref);
        setUser({ ...user, userImage: fileUrl });
        setImageUrl(fileUrl);
        setShowOptions(false);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const handleButtonClick = () => {
    setShowOptions(!showOptions);
  };

  const handleOpenModal = () => {
    setModalData({ currentEmail: "", newEmail: "", password: "" });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    fetchUser(); // Fetch user data again after closing the modal
  };

  const handleOpenPasswordModal = () => {
    setShowPasswordModal(true);
  };

  const handleClosePasswordModal = () => {
    setShowPasswordModal(false);
    fetchUser(); // Refresh user data after closing the password modal
  };

  return (
    <div className='mt-5'>
      <div className="flex flex-wrap max-w-full max-h-full bg-white rounded-lg shadow-md px-4">
        <div className="w-full md:w-1/3">
          <div className="profile-photo text-center rounded-lg p-5 relative">
            <div className="photo-placeholder bg-white rounded-full h-48 w-48 mx-auto mt-5 flex items-center justify-center relative transition-all duration-300 ease-in-out hover:shadow-lg">
              {imageUrl ? (
                <img src={imageUrl} alt="Profile" className="w-full h-full object-cover rounded-full" />
              ) : (
                <img src={imageUrl} alt="Default User" className="w-full h-full object-cover rounded-full" />
              )}
              <button
                className="bg-gray-800 text-white p-3 w-10 h-10 rounded-full flex items-center justify-center absolute bottom-2.5 right-2.5 shadow-md transition-all duration-300 ease-in-out hover:bg-gray-700 hover:shadow-lg"
                onClick={handleButtonClick}
              >
                <FontAwesomeIcon icon={faPen} />
              </button>
            </div>
            {showOptions && (
              <div
                className="options-menu absolute mt-16 top-56 left-1/2 transform -translate-x-1/2 bg-white shadow-lg rounded-md p-1 w-32 text-left z-10 animate-fadeIn"
                ref={dropdownRef}
              >
                <label
                  htmlFor="file-upload"
                  className="block cursor-pointer p-1 w-full text-left transition-colors duration-300 ease-in-out rounded-md hover:bg-gray-200"
                >
                  Upload Photo
                </label>
                <input
                  id="file-upload"
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="hidden"
                />
                <button
                  className="w-full text-left p-1 transition-colors duration-300 ease-in-out rounded-md hover:bg-gray-200"
                  onClick={() => setImageUrl("")}
                >
                  Delete
                </button>
              </div>
            )}
          </div>
          <div className="personal-data">
            <div className="relative">
              <label className="block font-bold">
                Email
                <input
                  type="email"
                  name="email"
                  value={user.email}
                  disabled
                  className={`mt-1 p-2.5 border rounded-lg w-full transition-all duration-300 ease-in-out bg-gray-200 cursor-not-allowed ${
                    errors.email ? 'border-red-500' : 'border-gray-300'
                  }`}
                />
              </label>
              <button
                className="absolute top-9 right-3 bg-gray-800 text-white p-2 rounded-full flex items-center justify-center shadow-md transition-all duration-300 ease-in-out hover:bg-gray-700 hover:shadow-lg"
                onClick={handleOpenModal}
              >
                <FontAwesomeIcon icon={faPen} />
              </button>
            </div>
            <div className="relative">
              <label className="block mt-4 font-bold">
                Password
                <input
                  type="password"
                  value="********" // Placeholder value
                  disabled
                  className="mt-1 p-2.5 border rounded-lg w-full transition-all duration-300 ease-in-out bg-gray-200 cursor-not-allowed"
                />
              </label>
              <button
                className="absolute top-9 right-3 bg-gray-800 text-white p-2 rounded-full flex items-center justify-center shadow-md transition-all duration-300 ease-in-out hover:bg-gray-700 hover:shadow-lg"
                onClick={handleOpenPasswordModal}
              >
                <FontAwesomeIcon icon={faPen} />
              </button>
            </div>
          </div>
        </div>
        <div className="w-full md:w-2/3 mt-2 md:mt-0">
          <div className="additional-data rounded-lg p-5">
            <h2 className="text-2xl font-semibold text-gray-800 mb-5">Additional Data</h2>
            {/* Display success and error messages */}
            {successMessage && (
              <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 mb-3 rounded relative" role="alert">
                <strong className="font-bold">
                <i className="fas fa-check-circle"></i> Success!</strong>
                <span className="block sm:inline"> {successMessage}</span>
              </div>
            )}

            {errorMessage && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mb-3 rounded relative" role="alert">
                <strong className="font-bold">
                <i className="fas fa-exclamation-circle"></i> Error!</strong>
                <span className="block sm:inline"> {errorMessage}</span>
              </div>
            )}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <label className="block font-bold">
                First Name
                <input
                  type="text"
                  name="firstName"
                  value={user.firstName}
                  onChange={handleChange}
                  className={`mt-1 p-2.5 border rounded-lg w-full transition-all duration-300 ease-in-out ${
                    errors.firstName ? 'border-red-500' : 'border-gray-300'
                  }`}
                />
                {errors.firstName && <span className="text-red-500 text-sm">{errors.firstName}</span>}
              </label>
              <label className="block font-bold">
                Last Name
                <input
                  type="text"
                  name="lastName"
                  value={user.lastName}
                  onChange={handleChange}
                  className={`mt-1 p-2.5 border rounded-lg w-full transition-all duration-300 ease-in-out ${
                    errors.lastName ? 'border-red-500' : 'border-gray-300'
                  }`}
                />
                {errors.lastName && <span className="text-red-500 text-sm">{errors.lastName}</span>}
              </label>
              <label className="block font-bold">
                Middle Name
                <input
                  type="text"
                  name="middleName"
                  value={user.middleName}
                  onChange={handleChange}
                  className="mt-1 p-2.5 border border-gray-300 rounded-lg w-full transition-all duration-300 ease-in-out"
                />
              </label>
              <label className="block font-bold">
                Phone Number
                <input
                  type="tel"
                  name="phone"
                  value={user.phone}
                  onChange={handleChange}
                  className={`mt-1 p-2.5 border rounded-lg w-full transition-all duration-300 ease-in-out ${
                    errors.phone ? 'border-red-500' : 'border-gray-300'
                  }`}
                />
                {errors.phone && <span className="text-red-500 text-sm">{errors.phone}</span>}
              </label>
              <label className="block mb-4 font-bold">
                Date of Birth
                <input
                  type="date"
                  name="dateOfBirth"
                  value={user.dateOfBirth.toISOString().split('T')[0]}
                  onChange={handleChange}
                  className={`mt-1 p-2.5 border rounded-lg w-full transition-all duration-300 ease-in-out ${
                    errors.dateOfBirth ? 'border-red-500' : 'border-gray-300'
                  }`}
                />
                {errors.dateOfBirth && <span className="text-red-500 text-sm">{errors.dateOfBirth}</span>}
              </label>
              <label className="block mb-4 font-bold">
                Gender
                <select
                  name="gender"
                  value={user.gender}
                  onChange={handleChange}
                  className={`mt-1 p-2.5 border rounded-lg cursor-pointer w-full transition-all duration-300 ease-in-out ${
                    errors.gender ? 'border-red-500' : 'border-gray-300'
                  }`}
                >
                  <option value="">Select gender</option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                  <option value="3">Other</option>
                </select>
                {errors.gender && <span className="text-red-500 text-sm">{errors.gender}</span>}
              </label>
              <label className="block mb-4 font-bold">
                Bio
                <textarea
                  name="bio"
                  value={user.bio}
                  onChange={handleChange}
                  className={`mt-1 p-2.5 border rounded-lg w-full transition-all duration-300 ease-in-out ${
                    errors.bio ? 'border-red-500' : 'border-gray-300'
                  }`}
                  rows="4"
                />
                {errors.bio && <span className="text-red-500 text-sm">{errors.bio}</span>}
              </label>
            </div>
            <div className="change-password rounded-lg p-5">
              <button
                className={`bg-green-500 text-white p-3 mt-2 rounded-lg shadow-md transition-all duration-300 ease-in-out flex justify-center items-center 
                  ${isLoading ? 'bg-green-300 cursor-not-allowed' : 'hover:bg-green-600 hover:shadow-lg'}`}
                onClick={handleSubmit}
                disabled={isLoading}
                style={{ pointerEvents: isLoading ? 'cursor-not-allowed' : 'auto' }}
              >
                {isLoading ? (
                  <div className="flex space-x-1 px-4 py-2">
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                  </div>
                ) : 'Save Changes'}
              </button>
            </div>
          </div>
        </div>
      </div>

      <EmailUpdateModal
        isOpen={showModal}
        onClose={handleCloseModal}
        modalData={modalData}
        setModalData={setModalData}
        userId={userId} // Pass userId to modal
        urlPrefix={urlPrefix} // Pass urlPrefix to modal
        userEmail={user.email}
        errors={errors}
        setErrors={setErrors}
        fetchUser={fetchUser}  // Pass the fetchUser function
      />

      <PasswordUpdateModal
        isOpen={showPasswordModal}
        onClose={handleClosePasswordModal}
        userId={userId}
        urlPrefix={urlPrefix}
        setMessage={setMessage}
      />

      {/* Inline styles for the loading dots */}
      <style>
        {`
          .dot {
            height: 8px;
            width: 8px;
            margin: 0 2px;
            background-color: white;
            border-radius: 50%;
            display: inline-block;
            animation: dot-blink 1.4s infinite both;
          }
          .dot:nth-child(1) {
            animation-delay: -0.32s;
          }
          .dot:nth-child(2) {
            animation-delay: -0.16s;
          }
          @keyframes dot-blink {
            0% {
              opacity: 0.2;
              transform: scale(1);
            }
            20% {
              opacity: 1;
              transform: scale(1.2);
            }
            100% {
              opacity: 0.2;
              transform: scale(1);
            }
          }
        `}
      </style>
    </div>
  );
};

export default EditUser;