import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import logo from '../../src/style/Image/LMLogo.png'; // Assuming you have a white version of your logo

const Footer = () => {
  const navigate = useNavigate(); // Initialize navigate

  return (
    <footer className="bg-gradient-to-r from-purple-600 to-blue-600 py-12 border-t border-gray-300 text-white font-sans">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 pb-8">
          <div className="flex flex-col items-center md:items-start">
            <img src={logo} alt="Learning Mission Armenia Logo" className="h-52 w-60 -mt-6 mb-5" />
            <p className="-mt-10 text-white text-opacity-90 text-sm text-center md:text-left">
              Learning Mission Armenia is committed to providing world-class educational opportunities to learners of all ages. Join us in our mission to make education accessible and empowering.
            </p>
          </div>
          
          {/* Quick Links */}
          <div className="text-center md:text-left">
            <h3 className="text-xl font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <a 
                  className="hover:underline text-white text-opacity-90 cursor-pointer"
                  onClick={() => navigate('/about-us')} // Use navigate instead of setActivePage
                >
                  About Us
                </a>
              </li>
            </ul>
          </div>

          {/* Contact and Social Media Section */}
          <div className="text-center md:text-left">
            <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
            <p className="text-white text-opacity-90">
              Yerevan, Armenia<br />
              Email: <a href="mailto:learningmissionarmenia@gmail.com" className="hover:underline">learningmissionarmenia@gmail.com</a>
            </p>

            {/* Social Media Icons */}
            <div className="mt-4 flex justify-center space-x-4">
              <a href="https://www.facebook.com/LearningMissionArmenia/" className="text-white hover:text-blue-300">
                <i className="bi bi-facebook"></i>
              </a>            
              <a href="https://www.instagram.com/learningmissionarmenia/" className="text-white hover:text-blue-300">
                <i className="bi bi-instagram"></i>
              </a>
              <a href="https://www.linkedin.com/company/learning-mission-armenia" className="text-white hover:text-blue-300">
                <i className="bi bi-linkedin"></i>
              </a>
            </div>
          </div>
        </div>

        {/* Copyright Section */}
        <div className="border-t border-gray-400 pt-6">
          <p className="text-center text-white text-opacity-70 text-sm -mb-5">
            &copy; 2024 Learning Mission Armenia. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;