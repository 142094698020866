// firebaseClient.js
import { initializeApp } from "firebase/app";
import { getStorage, ref } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDmvKjG1pzoVqXWhJAhOmmV06ZCyLzO2bg",
  authDomain: "learningml-3ee6f.firebaseapp.com",
  projectId: "learningml-3ee6f",
  storageBucket: "learningml-3ee6f.appspot.com",
  messagingSenderId: "320015104387",
  appId: "1:320015104387:web:05138d071c0952a4ec1eb6",
  measurementId: "G-001JGCN970"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Storage
const storage = getStorage(app);

export { storage, ref };