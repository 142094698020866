import React from 'react';

const AccountItem = ({ account, handleAccountLogin }) => {
    const defaultPhoto = "https://bit.ly/3BQdTqk" // Change Default Account Photo
    const getAccountRole = (roleId) => {
        switch (roleId) {
            case 1:
                return 'SysAdmin';
            case 2:
                return 'Admin';
            case 4:
                return 'AccountManager';
            case 8:
                return 'Beneficiary';
            default:
                return 'Unknown';
        }
    };

    const accountRole = getAccountRole(account.accountRoleId);

    return (
        <div
            onClick={() => {}} // Call handleAccountLogin(account.id)
            className="h-full w-full rounded-lg shadow-md flex flex-col cursor-pointer border border-solid border-blue-950"
        >
            <div className="bg-slate-800 rounded">
                <img
                    src={account.accountImage || `${defaultPhoto}`}
                    className="w-full h-40 object-cover rounded mb-2"
                    alt={`${account.name}`}
                />
                <h1 className="p-2 text-lg font-bold text-white">{account.name}</h1>
                {account.description && (
                    <p className={`text-sm text-white pl-2 ${accountRole === 'Unknown' ? 'pb-3' : ''}`}>
                        {account.description}
                    </p>
                )}

                {/* Conditionally render the role badge only if it's not 'Unknown' */}
                {accountRole !== 'Unknown' && (
                    <div className="p-2 flex justify-end items-center mt-2">
                        <span className="bg-blue-600 text-white text-xs px-2 py-1 rounded-full">
                            {accountRole}
                        </span>
                    </div>
                )}

                {/* <div className="flex justify-end items-stretch p-2">
                    {userAccounts.some((userAccount) => userAccount.id === account.id) ? (
                      <button
                        className="btn mt-4 bg-green-500 text-white px-3 py-2 rounded-lg shadow-md flex justify-center items-center cursor-not-allowed"
                        disabled
                      >
                        Enrolled
                      </button>
                    ) : (
                      <button
                        onClick={() => handleJoinClick(account)}
                        className={`btn mt-4 text-white px-3 py-2 rounded-lg shadow-md transition-all duration-300 ease-in-out flex justify-center items-center 
                            ${joiningStatus[account.id] ? 'bg-blue-300 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600 hover:shadow-lg'}`}
                        disabled={joiningStatus[account.id]}
                        style={{ pointerEvents: joiningStatus[account.id] ? 'none' : 'auto' }}
                      >
                        {joiningStatus[account.id] ? (
                          <FontAwesomeIcon icon={faSpinner} spin />
                        ) : (
                          'Join'
                        )}
                      </button>
                    )}
                 </div> */}
            </div>
        </div>
    );
};

export default AccountItem;