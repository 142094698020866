import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { urlPrefix } from '../../Settings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import AccountList from './account-components/AccountList';
import { ClipLoader } from 'react-spinners';

const AllAccounts = () => {
  const [accounts, setAccounts] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const messageTimeoutRef = useRef(null);

  // Fetch accounts when the component mounts
  const fetchAccounts = async () => {
    setLoading(true);
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token'),
      },
    };

    try {
      const response = await fetch(`${urlPrefix}/Account/visible`, requestOptions);
      const data = await response.json();

      if (data && data.length > 0) {
        setAccounts(data);
      } else {
        setErrorMessage('No accounts found or data format is incorrect.');
        hideMessagesAfterTimeout();
      }
    } catch (error) {
      console.error('Error fetching accounts:', error);
      setErrorMessage('Error fetching accounts. Please try again.');
      hideMessagesAfterTimeout();
    } finally {
      setLoading(false); // Ensure the spinner stops after fetching
    }
  };

  // const handleJoinClick = async (account) => {
//   //   const accountId = account.id;

//   //   if (!userId) {
//   //     setErrorMessage('User not logged in.');
//   //     hideMessagesAfterTimeout();
//   //     return;
//   //   }

//   //   setJoiningStatus((prevStatus) => ({
//   //     ...prevStatus,
//   //     [accountId]: true, // Set the loading state for the clicked account
//   //   }));

//   //   const payload = {
//   //     userId: parseInt(userId, 10),
//   //     accountId,
//   //     userAccountRoleEnum: 8,
//   //   };

//   //   const requestOptions = {
//   //     method: 'POST',
//   //     headers: {
//   //       'Content-Type': 'application/json',
//   //       'Authorization': localStorage.getItem('token'),
//   //     },
//   //     body: JSON.stringify(payload),
//   //   };

//   //   try {
//   //     const response = await fetch(`${urlPrefix}/Account/visible`, requestOptions);

//   //     if (response.ok) {
//   //       setSuccessMessage('Successfully joined the account!');
//   //       // Add the account to the userAccounts state
//   //       setUserAccounts((prevAccounts) => [...prevAccounts, account]);
//   //       hideMessagesAfterTimeout();
//   //     } else {
//   //       setErrorMessage('Failed to join account. Please try again.');
//   //       hideMessagesAfterTimeout();
//   //     }
//   //   } catch (error) {
//   //     console.error('Error joining account:', error);
//   //     setErrorMessage('An error occurred. Please try again.');
//   //     hideMessagesAfterTimeout();
//   //   } finally {
//   //     setJoiningStatus((prevStatus) => ({
//   //       ...prevStatus,
//   //       [accountId]: false, // Reset the loading state for the clicked account
//   //     }));
//   //   }
//   // };

  useEffect(() => {
    fetchAccounts();
  }, []);

  const hideMessagesAfterTimeout = () => {
    if (messageTimeoutRef.current) {
      clearTimeout(messageTimeoutRef.current);
    }

    messageTimeoutRef.current = setTimeout(() => {
      setSuccessMessage('');
      setErrorMessage('');
    }, 3000);
  };

  return (
    <div className="mt-32 mb-5 px-4">
      {/* Back to Home Button */}
      <div className="flex justify-start mb-4 mt-5">
        <button
          onClick={() => navigate('/user')}
          className="flex items-center text-blue-600 hover:text-blue-800 font-semibold text-lg cursor-pointer"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" /> Back
        </button>
      </div>

      <h1 className="flex text-4xl text-sky-900 ml-3 mb-3">All Accounts</h1>

      {/* Success or Error Message */}
      {successMessage && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4" role="alert">
          <span className="block sm:inline">
            <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
            {successMessage}
          </span>
        </div>
      )}

      {errorMessage && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <span className="block sm:inline">
            <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
            {errorMessage}
          </span>
        </div>
      )}

      {/* Spinner while loading */}
      {loading ? (
        <div className="flex justify-center">
          <ClipLoader color="#000" loading={loading} size={50} />
        </div>
      ) : (
        <AccountList
          accounts={accounts}
          isShowAllEnabled={true}
        />
      )}
    </div>
  );
};

export default AllAccounts;