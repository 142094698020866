import React from "react";

const UserRow = ({ user, email, role }) => {
  return (
    <tr className="border-b bg-[#FFFFFF] text-[#000000] border-[#D1D5DB] shadow-md font-semibold text-base">
      <td className="px-4 py-2">
        <img 
          src={user.userImage || 'default-photo-url'} 
          alt={user.firstName} 
          className="w-10 h-10 rounded-full" 
        />
      </td>
      <td className="px-4 py-2">{user.firstName} {user.lastName}</td>
      <td className="px-4 py-2">{role}</td>
      <td className="px-4 py-2">{email}</td>
      <td className="px-4 py-2">{new Date(user.createDate).toLocaleDateString()}</td>
    </tr>
  );
};

export default UserRow;
