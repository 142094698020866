import React from 'react';
import { useNavigate } from 'react-router-dom';
import work from '../../images/workflows.webp';
import analytic from '../../images/analytics.webp';
import col from '../../images/cols.webp';

const features = [
  {
    id: 1,
    img: col,
    title: 'Real-time Collaboration',
    description: 'Our real-time collaboration tools enable seamless teamwork across different locations.',
  },
  {
    id: 2,
    img: analytic,
    title: 'Advanced Analytics',
    description: 'Track performance and growth with our advanced analytics tools.',
  },
  {
    id: 3,
    img: work,
    title: 'Customizable Workflows',
    description: 'Adapt workflows to fit your unique business needs.',
  },
];

const Features = () => {
  const navigate = useNavigate(); // Use navigate for routing

  return (
    <section id="features" className="p-20">
      <h2 className="text-3xl font-bold mb-8 text-center">Features</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {features.map(feature => (
          <div
            key={feature.id}
            className="p-6 rounded-lg shadow-lg hover:shadow-xl flex flex-col justify-between hover:bg-slate-100 transition-transform duration-300 transform hover:-translate-y-2"
          >
            <div>
              <img src={feature.img} alt={feature.title} className="mb-4 w-full h-80 object-cover rounded" />
              <h3 className="text-xl font-bold mb-2">{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
            <button
              onClick={() => navigate(`/learn-more-feature/${feature.id}`)} // Use navigate to go to the feature page
              className="text-blue-500 hover:underline mt-4 self-end"
            >
              Learn More <i className="bi-chevron-right small ms-1"></i>
            </button>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Features;