import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Navbar from '../../Navbar';
import Footer from '../../Footer';
import EditUser from '../EditUser';
import ContactUs from '../../landing/ContactUs';
import AccountList from './AccountList';
import { urlPrefix } from '../../../Settings';
import { ClipLoader } from 'react-spinners';
import AllAccounts from '../AllAccounts';

const UserDashboard = () => {
    const navigate = useNavigate()
    const [connectedAccounts, setConnectedAccounts] = useState([]);
    const [popularAccounts, setPopularAccounts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchConnectedAccounts();
        fetchPopularAccounts();
    }, []);

    const fetchConnectedAccounts = async () => {
        const token = localStorage.getItem('token');
        if (!token) return;

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };

        try {
            const response = await fetch(`${urlPrefix}/Account/users/accounts`, requestOptions);
            const data = await response.json();
            setConnectedAccounts(Array.isArray(data) ? data : []);
        } catch (error) {
            console.error('Error fetching connected accounts:', error);
        }
    };

    const fetchPopularAccounts = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
            },
        };

        try {
            const response = await fetch(`${urlPrefix}/Account/visible`, requestOptions);
            const data = await response.json();

            if (data && data.length > 0) {
                setPopularAccounts(data);
            }
        } catch (error) {
            console.error('Error fetching popular accounts:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleAccountLogin = async (accountId) => {
        const token = localStorage.getItem('token');
        if (!token) return;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };

        try {
            const response = await fetch(`${urlPrefix}/Account/login?accountId=${accountId}`, requestOptions);
            if (response.ok) {
                localStorage.setItem('selectedAccountId', accountId);
            } else {
                console.error('Error logging in to the account:', response.statusText);
            }
        } catch (error) {
            console.error('Error during account login:', error);
        }
    };

    const handleSeeAllClick = () => {
        navigate('all-accounts');
    };

    return (
        <div className="flex flex-col min-h-screen">
            {/* Navbar */}
            <Navbar />

            <div className="flex-1">
                <Routes>
                    <Route
                        path="/"
                        element={
                            <>
                                {/* Enrolled Classes Section */}
                                <div className="user-connected-accounts mt-32 mx-4">
                                    <h2 className="text-2xl font-bold mb-4">Enrolled Classes</h2>
                                    {loading ? (
                                        <div className="flex justify-center">
                                            <ClipLoader color="#000" loading={loading} size={50} />
                                        </div>
                                    ) : connectedAccounts.length > 0 ? (
                                        <AccountList
                                            accounts={connectedAccounts}
                                            handleAccountLogin={handleAccountLogin}
                                        />
                                    ) : (
                                        <p className="text-center">You are not enrolled in any classes yet.</p>
                                    )}
                                </div>

                                {/* Most Popular Section */}
                                <div className="most-popular-accounts mt-16 mx-4 mb-5">
                                    <h2 className="text-2xl font-bold mb-4">Classes To Enroll</h2>
                                    {loading ? (
                                        <div className="flex justify-center">
                                            <ClipLoader color="#000" loading={loading} size={50} />
                                        </div>
                                    ) : popularAccounts.length > 0 ? (
                                        <AccountList
                                            accounts={popularAccounts}
                                            handleAccountLogin={handleAccountLogin}
                                            isShowAllEnabled={true}
                                            handleShowAll={handleSeeAllClick}
                                        />
                                    ) : (
                                        <p className="text-center">No popular classes available at the moment.</p>
                                    )}
                                </div>
                            </>
                        }
                    />

                    {/* All Classes Section */}
                    <Route path="all-accounts" element={<AllAccounts />} />
                    <Route path="/edit-profile" element={<EditUser userId={localStorage.getItem("userId")}/>} />
                </Routes>
            </div>

            {/* Contact Us and Footer */}
            <ContactUs />
            <Footer />
        </div>
    );
};

export default UserDashboard;