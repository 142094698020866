import React, { useState } from 'react';
import { FaCheckCircle, FaClock, FaTrash, FaEllipsisV } from 'react-icons/fa';
import AboutUser from './AboutUser'; // Import the AboutUser component

const UserRow = ({ user, dropdownOpen, toggleDropdown, onUserDelete }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const renderStatusIcon = (status) => {
    switch (status) {
      case 2:
        return <FaCheckCircle className="text-[#00f100] mr-2" />; // Green
      case 1:
        return <FaClock className="text-[#ffde23] mr-2" />; // Yellow
      case 3:
        return <FaTrash className="text-[#ff0000] mr-2" />; // Red
      default:
        return null;
    }
  };

  const statusToText = (status) => {
    switch (status) {
      case 2:
        return "Active";
      case 1:
        return "Pending";
      case 3:
        return "Deleted";
      default:
        return null;
    }
  };

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {/* When clicking the row, open the modal */}
      <tr
        key={user.id}
        className="hover:bg-[#F3F4F6] transition duration-300 ease-in-out cursor-pointer"
        onClick={openModal}
      >
        <td className="py-4 px-8 border-b border-[#E5E7EB]">
          <img
            src={user.userImage || 'default-photo-url'}
            alt={user.firstName}
            className="h-10 w-10 rounded-full object-cover shadow-sm"
          />
        </td>
        <td className="py-4 px-8 border-b border-[#E5E7EB] text-[#000000] font-semibold text-lg">
          {user.firstName} {user.lastName}
        </td>
        <td className="py-4 px-8 border-b border-[#E5E7EB] text-[#000000] font-semibold text-lg">
          {user.email}
        </td>
        <td className="py-4 px-8 border-b border-[#E5E7EB] text-[#000000] text-xl">
          {new Date(user.createDate).toLocaleDateString()}
        </td>
        <td className="py-4 px-8 border-b border-[#E5E7EB] text-xl font-bold">
          <div className="flex items-center">
            {renderStatusIcon(user.statusId)}
            <span className="ml-2 capitalize text-[#000000] font-medium text-base">
              {statusToText(user.statusId)}
            </span>
          </div>
        </td>
        <td className="py-4 px-8 border-b border-[#E5E7EB] relative">
          <div className="flex justify-center">
            <button
              onClick={() => toggleDropdown(user.id)}
              className="focus:outline-none text-[#000000] hover:text-[#374151] transition duration-200"
            >
              <FaEllipsisV />
            </button>
          </div>
          {dropdownOpen === user.id && (
            <div className="absolute right-0 top-0 mt-2 bg-[#FFFFFF] border border-[#E5E7EB] shadow-lg rounded-lg z-10">
              <button
                onClick={onUserDelete}
                className="block px-4 py-2 text-left text-sm text-[#ff0000] hover:bg-[#FEF2F2] w-full transition duration-200"
              >
                Delete
              </button>
            </div>
          )}
        </td>
      </tr>

      {/* Modal to show more details */}
      {isModalOpen && (
        <AboutUser
          isOpen={isModalOpen}
          onClose={closeModal}
          userId={user.id}
        />
      )}
    </>
  );
};

export default UserRow;