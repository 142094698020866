import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams to get the id from the URL

const caseStudies = [
  {
    id: 7,
    img: require('../../images/c1.webp'),
    title: 'Case Study One: Construction Company',
    description: 'How our product helped a construction company improve efficiency.',
    details: [
      'Background: A leading construction company was facing challenges with project management and communication, which resulted in delays and resource wastage.',
      'Solution: Our product provided real-time collaboration tools, customizable workflows, and task management systems, allowing the company to streamline operations.',
      'Results: The company experienced a 25% increase in productivity and a 30% reduction in project delays by effectively utilizing resources and improving team communication.',
      'Additional Benefits: Automated processes and the ability to manage tasks remotely allowed the company to focus on higher-value activities, boosting overall profitability.',
    ],
  },
  {
    id: 8,
    img: require('../../images/c2.webp'),
    title: 'Case Study Two: Training Center',
    description: 'Implementation of our product in a training center for better learning outcomes.',
    details: [
      'Background: A training center was struggling to engage students and track their progress, leading to lower completion rates and reduced student satisfaction.',
      'Solution: The training center adopted our advanced analytics and real-time collaboration tools, which enabled instructors to monitor student progress in real-time and provide personalized feedback.',
      'Results: The center saw a 20% increase in student satisfaction and a 15% improvement in learning outcomes, with higher completion rates across courses.',
      'Additional Benefits: The data-driven approach allowed the center to continuously improve its course offerings, ensuring a tailored learning experience for each student.',
    ],
  },
  {
    id: 9,
    img: require('../../images/c3.webp'),
    title: 'Case Study Three: Global Industry',
    description: 'A global industry leader leveraged our solutions to improve efficiency and scale operations.',
    details: [
      'Background: A global industry leader needed a solution to scale their operations and improve efficiency across multiple locations.',
      'Solution: The company implemented our customizable workflows and advanced analytics tools, which provided them with real-time insights into operations across all locations.',
      'Results: With our solutions, the company achieved a 40% increase in operational efficiency and a 50% reduction in manual errors.',
      'Additional Benefits: By integrating our tools with their existing systems, the company was able to maintain a seamless workflow while scaling globally, ensuring consistent performance and quality.',
    ],
  },
];

const LearnMoreCaseStudy = () => {
  const { id } = useParams(); // Get the id from the URL params
  const caseStudy = caseStudies.find(item => item.id === parseInt(id));

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component loads
  }, []);

  if (!caseStudy) {
    return <div>Case study not found</div>;
  }

  return (
    <section className="p-16 mt-20 bg-gray-50 min-h-screen">
      <div className="container mx-auto">
        <h2 className="text-5xl font-bold mb-8 text-center text-blue-700">{caseStudy.title}</h2>
        <div className="flex flex-col md:flex-row mb-8">
          <div className="md:w-1/4">
            <img src={caseStudy.img} alt={caseStudy.title} className="w-full h-auto rounded-lg shadow-md" />
          </div>
          <div className="md:w-1/2 md:pl-10 mt-6 md:mt-0">
            <p className="text-gray-700 text-lg">{caseStudy.description}</p>
            <ul className="list-disc list-inside mt-4 text-gray-700">
              {caseStudy.details.map((detail, index) => (
                <li key={index} className="mt-2">{detail}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LearnMoreCaseStudy;