import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { urlPrefix } from "../Settings";
import "react-phone-input-2/lib/style.css";  // Import styles for the phone input component
import PhoneInput from "react-phone-input-2";
import "bootstrap/dist/css/bootstrap.min.css";
import '@fortawesome/fontawesome-free/css/all.min.css';

const RegisterInfo = () => {
    const userTypeEnum = ["", "Admin", "AccountManager", "Beneficiary", "Investor"];
    const userGender = ["", "Male", "Female", "Other"];
    const navigate = useNavigate();
    const [Gender, setGender] = useState(userGender[0]);
    const [UserType, setUserType] = useState(userTypeEnum[0]);
    const [FirstName, setFirstName] = useState("");
    const [LastName, setLastName] = useState("");
    const [MiddleName, setMiddleName] = useState("");
    const [DateOfBirth, setDateOfBirth] = useState("");
    const [PhoneNumber, setPhoneNumber] = useState(""); // Store full phone number
    const [Metadata, setMetadata] = useState("");
    const [successMessage, setSuccessMessage] = useState(""); // For success message
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false); // Loading state

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true); // Set loading to true when submitting

        const requestOptions = {
            method: "POST",
            headers: { 
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token") 
            },
            body: JSON.stringify({
                "userTypeEnum": 2,
                "firstName": FirstName,
                "lastName": LastName,
                "middleName": MiddleName,
                "dateOfBirth": DateOfBirth,
                "phoneNumber": PhoneNumber,  // Save full phone number with country code
                "gender": userGender.indexOf(Gender),
                "userMetadata": Metadata
            }),
        };
    
        fetch(`${urlPrefix}/User/userInfoRegistration`, requestOptions)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Failed to submit");
                }
            })
            .then((result) => {
                // Handle success
                setSuccessMessage("Registration successful!");
                localStorage.removeItem('id');
                localStorage.removeItem('token');
                localStorage.removeItem('statusId');
                setError("");  // Clear any existing errors

                setTimeout(() => {
                    setSuccessMessage(""); // Clear success message after 2 seconds
                    navigate('/login'); // Navigate to login page
                }, 2000);
            })
            .catch((error) => {
                console.error("Error:", error);
                setError("An error occurred. Please try again.");
                setTimeout(() => setError(""), 2000); // Clear error after 2 seconds
            })
            .finally(() => {
                setIsLoading(false); // Reset loading state when done
            });
    };
    
    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-gray-100 to-indigo-300">
            <div className="w-full max-w-6xl p-5">
                <div className="bg-white border border-gray-300 p-5 rounded-lg shadow-lg">
                    <h2 className="text-center text-2xl text-blue-600 mb-6 uppercase">
                        Personal Information
                    </h2>
                     {/* Success Message */}
                {successMessage && (
                    <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 mb-3 rounded relative" role="alert">
                        <strong className="font-bold">
                            <i className="fas fa-check-circle"></i> Success!
                        </strong>
                        <span className="block sm:inline"> {successMessage}</span>
                    </div>
                )}

                    {/* Error Message */}
                {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mb-3 rounded relative" role="alert">
                        <strong className="font-bold">
                            <i className="fas fa-exclamation-circle"></i> Error!
                        </strong>
                        <span className="block sm:inline"> {error}</span>
                    </div>
                )}

                    <form onSubmit={handleSubmit} className="space-y-6">      
                        <div className="flex flex-wrap -mx-3">
                            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="first_name">
                                    First Name
                                </label>
                                <input
                                    type="text"
                                    id="first_name"
                                    className={`block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${error === "First Name is Required" ? 'border-red-500' : ''}`}
                                    placeholder="Enter Your First Name"
                                    value={FirstName}
                                    onChange={(evt) => setFirstName(evt.target.value)}
                                    required
                                />
                            </div>
                            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="last_name">
                                    Last Name
                                </label>
                                <input
                                    type="text"
                                    id="last_name"
                                    className="block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    placeholder="Enter Your Last Name"
                                    value={LastName}
                                    onChange={(evt) => setLastName(evt.target.value)}
                                    required
                                />
                            </div>
                            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="middle_name">
                                    Middle Name
                                </label>
                                <input
                                    type="text"
                                    id="middle_name"
                                    className="block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    placeholder="Enter Your Middle Name"
                                    value={MiddleName}
                                    onChange={(evt) => setMiddleName(evt.target.value)}
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3">
                            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="phone">
                                    Phone Number
                                </label>
                                <PhoneInput
                                    country={'us'}
                                    value={PhoneNumber}
                                    onChange={setPhoneNumber}
                                    inputProps={{
                                      name: 'phone',
                                      required: true,
                                      autoFocus: true,
                                      className: "block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 pl-12 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    }}
                                />
                            </div>
                            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="date_of_birth">
                                    Date of Birth
                                </label>
                                <input type="date" id="date_of_birth" className="block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    value={DateOfBirth}
                                    onChange={(evt) => setDateOfBirth(evt.target.value)}
                                    required />
                            </div>
                            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="gender">
                                    Gender
                                </label>
                                <select id="gender" className="block w-full bg-gray-200 text-gray-700 border cursor-pointer border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    value={Gender}
                                    onChange={(evt) => setGender(evt.target.value)}
                                    required>
                                    <option value={userGender[0]}>Select Gender</option>
                                    <option value={userGender[1]}>Male</option>
                                    <option value={userGender[2]}>Female</option>
                                    <option value={userGender[3]}>Other</option>
                                </select>                                         
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3">
                            <div className="w-full px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="user_metadata">
                                    Other Details
                                </label>
                                <textarea
                                    id="user_metadata"
                                    className="block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    rows={3}
                                    value={Metadata}
                                    onChange={(evt) => setMetadata(evt.target.value)}
                                />
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <button
                                type="submit"
                                className={`inline-block py-2 px-4 rounded font-bold text-white 
                                    ${isLoading ? 'bg-blue-300 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-700'}`}
                                disabled={isLoading}
                                style={{ pointerEvents: isLoading ? 'cursor-not-allowed' : 'auto' }}
                            >
                                {isLoading ? (
                                    <div className="flex space-x-1">
                                        <span className="dot"></span>
                                        <span className="dot"></span>
                                        <span className="dot"></span>
                                    </div>
                                ) : 'Submit'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {/* Inline styles for the loading dots */}
            <style>
                {`
                    .dot {
                        height: 8px;
                        width: 8px;
                        margin: 0 2px;
                        background-color: white;
                        border-radius: 50%;
                        display: inline-block;
                        animation: dot-blink 1.4s infinite both;
                    }
                    .dot:nth-child(1) {
                        animation-delay: -0.32s;
                    }
                    .dot:nth-child(2) {
                        animation-delay: -0.16s;
                    }
                    @keyframes dot-blink {
                        0% {
                            opacity: 0.2;
                            transform: scale(1);
                        }
                        20% {
                            opacity: 1;
                            transform: scale(1.2);
                        }
                        100% {
                            opacity: 0.2;
                            transform: scale(1);
                        }
                    }
                `}
            </style>
        </div>     
    );
};

export default RegisterInfo;