import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import ClassHeader from './ClassHeader'; // Assuming you have this component
import UserOfClassTable from './UserOfClassTable'; // Assuming you have this component
import { urlPrefix } from '../../../../../../Settings';

const ClassView = () => {
  const { id } = useParams(); // Access the class ID from the URL
  const location = useLocation(); // Get passed state from the navigation
  const [classData, setClassData] = useState(location.state?.classItem || null);
  const [users, setUsers] = useState([]); // Assuming you'll fetch users for the class
  const [loading, setLoading] = useState(!classData); // Loading state for fetching

  // Fetch class data if it wasn't passed through the navigation state
  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error('No token found');
        return;
      }

      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        }
      };

      try {
        setLoading(true); // Start loading
        const url = `${urlPrefix}/Account/users?accountId=${classData.id}`; // Assuming you're fetching users for a specific class using the class ID
        const response = await fetch(url, requestOptions);
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setUsers(data); // Set the fetched users
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false); // Stop loading after fetch completes
      }
    };

    fetchUserData(); // Call the function to fetch user data
  }, [id]); // Dependency array includes `id` to re-fetch if the ID changes

  if (loading) {
    return <div>Loading...</div>; // Show a loading state while data is being fetched
  }

  if (!classData) {
    return <div>Class data not found</div>; // Handle case where class data isn't available
  }

  return (
    <main className="p-6 min-w-full bg-[#FFFFFF] border border-[#D1D5DB] shadow-md rounded-lg">
      {/* Render class-specific details */}
      <ClassHeader
        className={classData.name}
        usersCount={users.length} // Updated to show fetched user count
        accountManager="John Doe"
        accountAdmin="Jane Smith"
        onboardingLink={classData.onboardingLink}
      />
      {/* Pass class-specific users or other details to the UserOfClassTable */}
      <UserOfClassTable users={users} />
    </main>
  );
};

export default ClassView;
