import React, { useState, useEffect } from 'react';
import UserTable from './UserTable';
import { urlPrefix } from '../../../../Settings';
import { FaFilter } from 'react-icons/fa';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(null);

  // Fetch data when component mounts
  useEffect(() => {
    const fetchUsers = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error('No token found');
        return;
      }
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        }
      };

      try {
        const url = `${urlPrefix}/User/filter?statusIds=1&statusIds=2`;
        const response = await fetch(url, requestOptions)
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  return (
    <div className="bg-[#ffffff] rounded-lg shadow-md p-4 md:p-6">
      <div className="flex justify-between mb-4">
        <input
          type="text"
          placeholder="Search for user"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="px-3 py-2 border border-[#d1d5db] rounded-md w-1/3"
        />
        <button
          onClick={ {} }
          className="flex items-center px-3 py-2 bg-[#2563eb] text-white rounded-md shadow-md"
        >
          <FaFilter className="mr-2" />
          Filter
        </button>
      </div>

      <UserTable
        users={users}
        searchTerm={searchTerm}
        dropdownOpen={dropdownOpen}
        toggleDropdown={toggleDropdown}
        isArchived={false}
      />

      {/* <FilterModal
        isOpen={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
        onFilter={handleFilter}
      /> */}
    </div>
  );
};

export default Users;
