import React, { useState } from 'react';
import AccountItem from './AccountItem';

const AccountList = ({ accounts, handleAccountLogin, isShowAllEnabled = false, handleShowAll }) => {
    const accountsPerRow = 4;
    const initialVisibleAccounts = 2 * accountsPerRow;
    const [visibleAccountsCount, setVisibleAccountsCount] = useState(initialVisibleAccounts);

    const visibleAccounts = accounts.slice(0, visibleAccountsCount);

    const handleShowAllClick = () => {
        if (handleShowAll) {
            handleShowAll();
        } else {
            setVisibleAccountsCount((prevCount) => prevCount + 2 * accountsPerRow);
        }
    };

    const handleShowLessClick = () => {
        setVisibleAccountsCount(initialVisibleAccounts);
    };

    return (
        <div>
            {/* Conditional Layout: Grid vs Horizontal Stack */}
            {isShowAllEnabled && 
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    {visibleAccounts.map((account) => (
                        <AccountItem
                            key={account.id}
                            account={account}
                            handleAccountLogin={handleAccountLogin}
                        />
                    ))}
                </div>
            }
            
            {isShowAllEnabled == false &&
                <div className="flex overflow-x-auto space-x-4 hide-scrollbar">
                    {visibleAccounts.map((account) => (
                        <div key={account.id} className="flex-shrink-0 w-64">
                            <AccountItem
                                account={account}
                                handleAccountLogin={handleAccountLogin}
                            />
                        </div>
                    ))}
                </div>
            }


            {/* Show "Show More" button only if there are more accounts to show */}
            {isShowAllEnabled && visibleAccountsCount < accounts.length && (
                <div className="flex justify-start mt-4">
                    <button
                        onClick={handleShowAllClick}
                        className="bg-blue-600 text-white px-4 py-2 rounded-lg"
                    >
                        {handleShowAll ? "Show All" : "Show More"}
                    </button>
                </div>
            )}

            {/* Show "Show Less" button if more than the initial number of accounts are visible */}
            {visibleAccountsCount > initialVisibleAccounts && (
                <div className="flex justify-start mt-4">
                    <button
                        onClick={handleShowLessClick}
                        className="bg-gray-600 text-white px-4 py-2 rounded-lg"
                    >
                        Show Less
                    </button>
                </div>
            )}
        </div>
    );
};

export default AccountList;