import React, { useState, useEffect } from 'react';
import ClassTable from './ClassTable';
import { urlPrefix } from '../../../../../Settings';

const Classes = () => {
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(null);

  // Fetch data when component mounts
  useEffect(() => {
    const fetchClasses = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error('No token found');
        return;
      }
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        }
      };

      try {
        const url = `${urlPrefix}/Account/filter`;
        const response = await fetch(url, requestOptions)
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        setFilteredClasses(data); 
      } catch (error) {
        console.error('Error fetching classes:', error);
      }
    };

    fetchClasses();
  }, []);

  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  return (
    <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
      <h2 className="text-xl md:text-2xl font-bold mb-4">Classes</h2>
      <ClassTable 
        dropdownOpen={dropdownOpen}
        toggleDropdown={toggleDropdown}
        classesData={filteredClasses}
      />
    </div>
  );
};

export default Classes;
